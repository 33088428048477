import React, { useState } from 'react';
import { Button, Table, TableBody, TableCell, TableHead, TableRow,
  Dialog, DialogTitle, DialogActions } from '@material-ui/core';
import { DeleteOutline, Edit, Cancel, AddCircle } from '@material-ui/icons';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { isEmpty } from 'lodash';
import useStyles from '../../theme/Styles';

const PaymentTable = ({ payments, setPayments, total, userHasEditPaymentsRole, userHasPayPaymentsRole, errors, setErrors }) => {
  const classes = useStyles();
  const [editable, setEditable] = useState();
  const [open, setOpen] = useState(false);
  let totalPayed = 0;
  let totalPayments = 0;
  const [currentPaymentKey, setcurrentPaymentKey] = useState();
  let hasErrors = false;
  const checkErrors = () => {
    if (!hasErrors && errors) setErrors(false);
  };

  const isValidDate = date => (!isNaN(Date.parse(date)));

  // const handleDeletePaymentsRow = (paymentKey) => ;
  const sortByDate = (obj, strict, attrName = 'programmedDate') => {
    let arry = [];
    let timeError = false;
    Object.keys(obj).forEach((key) => {
      const auxObj = obj[key];
      if (!isValidDate(auxObj[attrName])) timeError = true;
      arry.push({ ...auxObj, id: key });
    });
    arry = arry.sort((a, b) => (
      new Date(b[attrName]).getTime() - new Date(a[attrName]).getTime()
    ));
    return (strict && timeError) ? false : arry.reverse();
  };

  const [sortedPayments, setSortedPayments] = useState(sortByDate(payments));

  const auxSortedPayments = sortByDate(payments, true);
  if (auxSortedPayments && JSON.stringify(sortedPayments) !== JSON.stringify(auxSortedPayments)) {
    setSortedPayments(auxSortedPayments);
  }

  const getRandomKey = () => Math.random().toString(36).substring(7);

  const generateNewSubPayment = (amount = 0) => ({
    amount,
    payedDate: new Date().toISOString(),
  });

  const addSubPayment = (paymentId, amount) => {
    const newPayments = {
      ...payments,
      [paymentId]: {
        ...payments[paymentId],
        subpayments: {
          ...payments[paymentId].subpayments,
          [getRandomKey()]: generateNewSubPayment(amount),
        },
      },
    };
    // newPayments[paymentId].subpayments || {})[getRandomKey()] = generateNewSubPayment(amount);
    setPayments(newPayments);
  };

  return (
    <>
      <Table style={{ backgroundColor: 'white' }}>
        <TableHead>
          <TableRow>
            <TableCell size="small">Cuota Nro</TableCell>
            <TableCell>Fecha</TableCell>
            <TableCell size="small">Monto</TableCell>
            <TableCell>%</TableCell>
            <TableCell>Fecha Pagó</TableCell>
            <TableCell>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedPayments.map((pymnt, index) => {
            const paymentId = pymnt.id;
            const payment = payments[paymentId] || {};
            let { subpayments } = payment;
            if (Object.keys(subpayments || {}).length === 0) subpayments = null;
            const totalSubPayments = Object.values(subpayments || {}).reduce(
              (a, b) => ({ amount: parseFloat(a.amount || 0) + parseFloat(b.amount || 0) }), 0,
            ).amount || 0;
            totalPayments += parseFloat(payment.amount);
            if (payment.payedDate) totalPayed += parseFloat(payment.amount);
            else if (subpayments) totalPayed += parseFloat(totalSubPayments);
            if (!hasErrors && ((subpayments && totalSubPayments !== parseFloat(payment.amount) && payment.payedDate) || !isValidDate(payment.programmedDate))) {
              hasErrors = true;
              setErrors(true);
            }
            return (
              <>
                <TableRow key={paymentId}>
                  <TableCell size="small">
                    <div style={{ width: 30, paddingRight: 0 }}>{index + 1}</div>
                  </TableCell>
                  <TableCell>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        value={payment.programmedDate}
                        disabled={!userHasEditPaymentsRole}
                        onChange={(date) => {
                          const auxPayment = { ...payment };
                          if (isValidDate(date)) {
                            auxPayment.programmedDate = date.toISOString();
                          } else {
                            auxPayment.programmedDate = date;
                            setErrors(true);
                          }
                          const newPayments = { ...payments };
                          delete auxPayment.id;
                          newPayments[paymentId] = auxPayment;
                          setPayments(newPayments);
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </TableCell>
                  <TableCell size="small">
                    <input
                      value={payment.amount}
                      style={{ width: 50, border: 0, borderBottom: 1 }}
                      disabled={!userHasEditPaymentsRole}
                      onChange={(e) => {
                        payment.amount = e.target.value;
                        const newPayments = { ...payments };
                        delete payment.id;
                        newPayments[paymentId] = payment;
                        setPayments(newPayments);
                      }}
                    />
                    <br />
                    {subpayments && isEmpty(payment.payedDate) ? `(falta ${payment.amount - totalSubPayments})` : ''}
                  </TableCell>
                  <TableCell>
                    <span style={{ width: 20, border: 0, borderBottom: 1 }}>
                      {Math.floor(payment.amount / total * 100)}
                    </span>
                  </TableCell>
                  <TableCell style={{ paddingRight: 0 }}>
                    {isEmpty(payment.payedDate)
                      ? subpayments
                        ? (
                          <>
                            <Button
                              disabled={!userHasPayPaymentsRole}
                              variant="outlined"
                              onClick={() => {
                                addSubPayment(paymentId, payment.amount - totalSubPayments);
                              }}
                            >
                              Agregar fila
                            </Button>
                            <Button
                              variant="outlined"
                              disabled={!userHasPayPaymentsRole || parseFloat(payment.amount) !== totalSubPayments}
                              onClick={() => {
                                const today = new Date();
                                payment.payedDate = today.toISOString();
                                const newPayments = { ...payments };
                                delete payment.id;
                                newPayments[paymentId] = payment;
                                setPayments(newPayments);
                              }}
                            >
                          Finalizar
                            </Button>
                          </>
                        )
                        : (
                          <>
                            <Button
                              style={{ width: 100 }}
                              variant="outlined"
                              disabled={!userHasPayPaymentsRole}
                              onClick={() => {
                                const today = new Date();
                                payment.payedDate = today.toISOString();
                                const newPayments = { ...payments };
                                delete payment.id;
                                newPayments[paymentId] = payment;
                                setPayments(newPayments);
                              }}
                            >
                            Pagar
                            </Button>
                            <Button
                              style={{ width: 100 }}
                              variant="outlined"
                              disabled={!userHasPayPaymentsRole}
                              onClick={() => {
                                addSubPayment(paymentId, payment.amount);
                              }}
                            >
                            Parcial
                            </Button>
                          </>
                        )


                      : (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          {editable === paymentId
                            ? (
                              <>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    value={payment.payedDate}
                                    disabled={!userHasPayPaymentsRole}
                                    onChange={(date) => {
                                      const auxPayment = { ...payment };
                                      if (isValidDate(date)) {
                                        auxPayment.payedDate = date.toISOString();

                                        const newPayments = { ...payments };
                                        delete auxPayment.id;
                                        newPayments[paymentId] = auxPayment;
                                        setPayments(newPayments);
                                      } else {
                                        auxPayment.payedDate = payment.payedDate;
                                        setErrors(true);
                                      }
                                    }}
                                  />
                                </MuiPickersUtilsProvider>
                                <Button
                                  disabled={!userHasPayPaymentsRole}
                                  style={{ minWidth: 50 }}
                                  onClick={() => {
                                    payment.payedDate = '';
                                    const newPayments = { ...payments };
                                    delete payment.id;
                                    newPayments[paymentId] = payment;
                                    setPayments(newPayments);
                                    setEditable(null);
                                  }}
                                >
                                  <DeleteOutline />
                                </Button>
                              </>
                            )
                            : (
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                  disableToolbar
                                  style={{ minWidth: 50 }}
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  margin="normal"
                                  id="date-picker-inline"
                                  value={payment.payedDate}
                                  disabled
                                  onChange={(date) => {
                                    payment.payedDate = date.toISOString();
                                    const newPayments = { ...payments };
                                    delete payment.id;
                                    newPayments[paymentId] = payment;
                                    setPayments(newPayments);
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            )}
                          <Button
                            disabled={!userHasPayPaymentsRole}
                            style={{ minWidth: 50 }}
                            onClick={() => {
                              setEditable(editable === paymentId ? null : paymentId);
                            }}
                          >
                            {editable === paymentId ? <Cancel /> : <Edit />}
                          </Button>
                        </div>
                      )
                  }
                  </TableCell>
                  <TableCell>
                    <Button
                      disabled={!userHasEditPaymentsRole}
                      onClick={() => {
                        setOpen(true);
                        setcurrentPaymentKey(paymentId);
                      }}
                    >
                      <DeleteOutline />
                    </Button>
                    <Dialog
                      open={open}
                      onClose={() => setOpen(false)}
                      aria-labelledby="alert-dialog-title"
                    >
                      <div className={classes.alertModalContainer}>
                        <DialogTitle id="alert-dialog-title">Estas seguro que quieres eliminar esta fila?</DialogTitle>
                        <DialogActions>
                          <Button onClick={() => setOpen(false)} color="primary">
                        Cancelar
                          </Button>
                          <Button
                            onClick={() => {
                              const newPayments = { ...payments };
                              delete newPayments[currentPaymentKey];
                              if (Object.keys(newPayments).length === 0) {
                                // newPayments[0] = {
                                  // programmedDate: new Date().toISOString(),
                                  // amount: 0,
                                  // payedDate: '',
                                // };
                              }
                              setPayments(newPayments);
                              setOpen(false);
                            }}
                            color="primary"
                            autoFocus
                          >
                        Aceptar
                          </Button>
                        </DialogActions>
                      </div>
                    </Dialog>
                  </TableCell>
                </TableRow>
                {sortByDate(subpayments || {}, false, 'payedDate').map((sp) => {
                  const spk = sp.id;
                  return (
                    <TableRow>
                      <TableCell size="small" />
                      <TableCell />
                      <TableCell size="small">
                        <input
                          value={sp.amount}
                          style={{ width: 80, border: 0, borderBottom: 1 }}
                          disabled={!userHasPayPaymentsRole}
                          onChange={(e) => {
                            sp.amount = e.target.value;
                            delete sp.id;
                            payment.subpayments[spk] = sp;
                            const newPayments = { ...payments };
                            delete payment.id;
                            newPayments[paymentId] = payment;
                            setPayments(newPayments);
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <span style={{ width: 20, border: 0, borderBottom: 1 }}>
                          {Math.floor(sp.amount / total * 100)}
                        </span>
                      </TableCell>
                      <TableCell>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            value={sp.payedDate}
                            disabled={!userHasPayPaymentsRole}
                            onChange={(date) => {
                              const auxSP = { ...sp };
                              if (isValidDate(date)) {
                                auxSP.payedDate = date.toISOString();

                                delete auxSP.id;
                                payment.subpayments[spk] = auxSP;
                                const newPayments = { ...payments };
                                delete payment.id;
                                newPayments[paymentId] = payment;
                                setPayments(newPayments);
                              } else {
                                auxSP.payedDate = sp.payedDate;
                                setErrors(true);
                              }
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </TableCell>
                      <TableCell style={{ paddingLeft: 0 }}>
                        <Button
                          disabled={!userHasPayPaymentsRole}
                          style={{ marginLeft: -40 }}
                          onClick={() => {
                            const newPayments = { ...payments };
                            delete newPayments[paymentId].subpayments[spk];
                            setPayments(newPayments);
                          }}
                        >
                          <DeleteOutline />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            );
          })}
          {checkErrors()}
        </TableBody>
      </Table>
      <span
        className={classes.paymentTotalText}
        style={{ marginTop: '20px', color: (total - totalPayments === 0) ? 'white' : '#642425' }}
      >
        {total === 0
          ? `Total Cuotas: ${totalPayments}$ `
          : `Total Cuotas: ${totalPayments}$ (${Math.floor(totalPayments / total * 100)}%) `
        }
        {`Restante: ${(total - totalPayments).toFixed(2)}$`}
      </span>
      <span className={classes.paymentTotalText}>
        {total === 0
          ? `Monto Pagado: ${totalPayed}$ `
          : `Monto Pagado: ${totalPayed}$ (${Math.floor(totalPayed / total * 100)}%) `
        }
        {`Restante: ${(total - totalPayed).toFixed(2)}$`}
      </span>
      {userHasEditPaymentsRole
        && (
        <Button
          style={{ color: 'white', border: '1px solid white', marginTop: 10, width: 190, fontSize: 18 }}
          onClick={() => {
            const paymentId = (Object.keys(payments).length > 0 ? Math.max(...Object.keys(payments)) : 0) + 1;
            const paymentsList = [...(sortedPayments || [])];
            const dateString = (paymentsList[paymentsList.length - 1] || {}).programmedDate;
            const lastDate = isValidDate(dateString) ? new Date(dateString) : new Date();
            lastDate.setMonth(lastDate.getMonth() + 1);
            const newPayments = { ...payments };
            newPayments[paymentId] = {
              programmedDate: lastDate.toISOString(),
              amount: 0,
              payedDate: '',
            };
            setPayments(newPayments);
          }}
        >
          Agregar Fila
        </Button>
        )
      }
    </>
  );
};

export default PaymentTable;
