import store from '../../config/ConfigStore';

export const NEWS_ROLE = 'NEWS_ROLE'; //
export const APARTMENTS_VIEW_ROLE = 'APARTMENTS_VIEW_ROLE'; //
export const APARTMENTS_EDIT_ROLE = 'APARTMENTS_EDIT_ROLE';
export const PAYMENTS_VIEW_ROLE = 'PAYMENTS_VIEW_ROLE';
export const PAYMENTS_EDIT_ROLE = 'PAYMENTS_EDIT_ROLE';
export const PAYMENTS_PAY_ROLE = 'PAYMENTS_PAY_ROLE';
export const BILLS_VIEW_ROLE = 'BILLS_VIEW_ROLE';
export const BILLS_EDIT_ROLE = 'BILLS_EDIT_ROLE';
export const NEXT_PAYMENTS_ROLE = 'NEXT_PAYMENTS_ROLE';
export const STEPS_EDIT = 'STEPS_EDIT';
export const NOTIFICATIONS_ROLE = 'NOTIFICATIONS_ROLE';

const accounts = [
  {
    email: 'admin@triii.com.bo',
    roles: [NEWS_ROLE, APARTMENTS_VIEW_ROLE, APARTMENTS_EDIT_ROLE, PAYMENTS_VIEW_ROLE, PAYMENTS_EDIT_ROLE, PAYMENTS_PAY_ROLE, BILLS_VIEW_ROLE, BILLS_EDIT_ROLE, NEXT_PAYMENTS_ROLE, STEPS_EDIT, NOTIFICATIONS_ROLE], // eslint-disable-line
  },
  {
    email: 'user@triii.com.bo',
    roles: [], // eslint-disable-line
  },
  // {
  //   email: 'dcanedo@effizienz.com.bo',
  //   roles: [NEWS_ROLE, APARTMENTS_VIEW_ROLE, APARTMENTS_EDIT_ROLE, PAYMENTS_VIEW_ROLE, PAYMENTS_PAY_ROLE, PAYMENTS_EDIT_ROLE, BILLS_VIEW_ROLE, BILLS_EDIT_ROLE, NEXT_PAYMENTS_ROLE, STEPS_EDIT, NOTIFICATIONS_ROLE], // eslint-disable-line
  // },
  {
    email: 'durquidi@effizienz.com.bo',
    roles: [NEWS_ROLE, APARTMENTS_VIEW_ROLE, APARTMENTS_EDIT_ROLE, PAYMENTS_VIEW_ROLE, PAYMENTS_EDIT_ROLE, PAYMENTS_PAY_ROLE, BILLS_VIEW_ROLE, BILLS_EDIT_ROLE, NEXT_PAYMENTS_ROLE, STEPS_EDIT, NOTIFICATIONS_ROLE], // eslint-disable-line
  },
  // {
  //   email: 'amalky@effizienz.com.bo',
  //   roles: [NEWS_ROLE, APARTMENTS_VIEW_ROLE, APARTMENTS_EDIT_ROLE, PAYMENTS_VIEW_ROLE, PAYMENTS_EDIT_ROLE, BILLS_VIEW_ROLE, NEXT_PAYMENTS_ROLE, NOTIFICATIONS_ROLE], // eslint-disable-line
  // },
  {
    email: 'larce@effizienz.com.bo',
    roles: [APARTMENTS_VIEW_ROLE, PAYMENTS_VIEW_ROLE, PAYMENTS_PAY_ROLE, BILLS_VIEW_ROLE, BILLS_EDIT_ROLE, NEXT_PAYMENTS_ROLE], // eslint-disable-line
  },
  {
    email: 'cguarana@effizienz.com.bo',
    roles: [NEWS_ROLE, STEPS_EDIT, NOTIFICATIONS_ROLE],
  },
  {
    email: 'selva@twiiti.com',
    roles: [NEWS_ROLE, NOTIFICATIONS_ROLE],
  },
  {
    email: 'christina@twiiti.com',
    roles: [NEWS_ROLE, NOTIFICATIONS_ROLE],
  },
  {
    email: 'financiamientoclientes@effizienz.com.bo',
    roles: [APARTMENTS_VIEW_ROLE, PAYMENTS_VIEW_ROLE],
  },
  {
    email: 'kkawamura@effizienz.com.bo',
    roles: [NEWS_ROLE, APARTMENTS_VIEW_ROLE, APARTMENTS_EDIT_ROLE, PAYMENTS_VIEW_ROLE, PAYMENTS_EDIT_ROLE, BILLS_VIEW_ROLE, NEXT_PAYMENTS_ROLE, NOTIFICATIONS_ROLE], // eslint-disable-line
  },
  {
    email: 'mchilo@effizienz.com.bo',
    roles: [APARTMENTS_VIEW_ROLE, PAYMENTS_VIEW_ROLE, BILLS_VIEW_ROLE, NEXT_PAYMENTS_ROLE], // eslint-disable-line
  },
  {
    email: 'mjustiniano@effizienz.com.bo',
    roles: [NEWS_ROLE, APARTMENTS_VIEW_ROLE, APARTMENTS_EDIT_ROLE, PAYMENTS_VIEW_ROLE, PAYMENTS_EDIT_ROLE, BILLS_VIEW_ROLE, NEXT_PAYMENTS_ROLE, NOTIFICATIONS_ROLE], // eslint-disable-line
  },
];

export const getUserAccount = email => accounts.find(acc => acc.email === email);

export const userHasAccess = (requiredRole) => {
  const userRoles = store.getState().user.account.roles;

  return userRoles.includes(requiredRole);
};
