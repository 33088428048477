import React, { useState } from 'react';
import { Button, Grid, Select, MenuItem } from '@material-ui/core';
import firebase from 'firebase';
import logger from '../../actions/helpers';
import useStyles from '../../theme/Styles';

const ChangeStage = ({ handleClose, step, stage, currentState }) => {
  const classes = useStyles();
  const [completed, setCompleted] = useState(currentState);
  const [loading, setLoading] = useState(false);


  const pushToFirebase = () => {
    setLoading(true);
    firebase.database().ref(`/steps/${stage}/${step}`).update({
      completed,
    }).then(() => {
      handleClose();
      setLoading(false);
    })
      .catch(() => setLoading(false));
    logger('ChangeStage/pushToFirebase', { path: `/steps/${stage}/${step}`, completed });
  };

  return (
    <div className={classes.smallModalContainer}>
      <span className={classes.subTitle}>Cambiar fecha</span>
      <br />
      <span className={classes.subTitle}>{step}</span>
      <Grid container>
        <Select
          value={completed}
          onChange={(e) => { setCompleted(e.target.value); }}
          classes={{
            root: classes.whiteText,
            icon: classes.whiteText,
          }}
        >
          <MenuItem
            value={false}
          >
            En Proceso
          </MenuItem>
          <MenuItem
            value
          >
            Completado
          </MenuItem>
        </Select>
      </Grid>
      <Button
        variant="outlined"
        color="primary"
        className={!loading ? classes.button : classes.buttonDisabled}
        onClick={pushToFirebase}
        disabled={loading}
      >
        Cambiar
      </Button>
      <Button
        color="primary"
        className={!loading ? classes.button : classes.buttonDisabled}
        onClick={handleClose}
        disabled={loading}
      >
      Cancelar
      </Button>
    </div>
  );
};

export default ChangeStage;
