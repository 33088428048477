import React from 'react';
import { Card, CardMedia, CardContent, Chip } from '@material-ui/core';
import { isEmpty, isArray } from 'lodash';

import useStyles from '../../theme/Styles';

const NewsList = ({ news }) => {
  const classes = useStyles();
  console.log(news);
  return (
    <div className={classes.newsContainer}>
      {isArray(news) && news.map((newsItem, key) => (
        <Card className={classes.newsCard} key={key}>
          {!isEmpty(newsItem.cover) && (
            <CardMedia
              className={classes.newsImage}
              image={newsItem.cover}
              title="Cover"
            />
          )}
          <CardContent className={isEmpty(newsItem.cover) ? classes.newsCardContentNoImage : classes.newsCardContent}>
            <div className={classes.newsTitle}>{newsItem.title}</div>
            <div className={classes.newsDate}>
              {newsItem.createdAt}
              <div>
                {!isEmpty(newsItem.phase)
                  && (
                    <Chip
                      label={newsItem.phase}
                      variant="outlined"
                      color="default"
                      size="small"
                    />
                  )}
                {!isEmpty(newsItem.stage)
                  && (
                    <Chip
                      label={newsItem.stage}
                      color="default"
                      size="small"
                    />
                  )}
              </div>
            </div>
            <div className={classes.newsContent}>{newsItem.body}</div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default NewsList;
