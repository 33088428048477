import React, { useState } from 'react';
import { Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button } from '@material-ui/core';
import useStyles from '../../theme/Styles';
import Stages from '../common/Stages';
import Actions from './Actions';

const StepsList = ({ steps }) => {
  const classes = useStyles();
  const [stage, setStage] = useState(1);

  const showSteps = steps[stage];

  return (
    <div className={classes.newsContainer}>
      {Stages.map(item => (
        <Button
          className={stage === item.value ? classes.tabButtonsSelected : classes.tabButtons}
          onClick={() => setStage(item.value)}
        >
          {item.name}
        </Button>
      ))}
      <Paper style={{ borderTopLeftRadius: 0 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Etapa</TableCell>
              <TableCell align="left">Fecha</TableCell>
              <TableCell align="left">Completado</TableCell>
              <TableCell align="left">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(showSteps).map((key) => {
              let dateString = 'No configurada';
              if (showSteps[key].date) {
                dateString = new Date(showSteps[key].date);
                dateString = dateString.toDateString();
              }
              return (
                <TableRow>
                  <TableCell align="left">{key}</TableCell>
                  <TableCell align="left">{dateString}</TableCell>
                  <TableCell align="left">{showSteps[key].completed ? 'Completado' : 'En proceso'}</TableCell>
                  <TableCell align="left">
                    <Actions step={key} stage={stage} completed={showSteps[key].completed} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};


export default StepsList;
