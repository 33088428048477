import React, { useState } from 'react';
import { Select, MenuItem, Modal } from '@material-ui/core';
import EditUser from './EditUser';
import Notify from '../notifications/SimpleForm';
import SelectParkingLot from './SelectParkingLot';
import SelectStorageUnit from './SelectStorageUnit';
import PaymentsPlan from '../payments/PaymentsPlan';
import Bills from './Bills';
import DeleteApartmentInfo from './DeleteApartmentInfo';
import {
  userHasAccess,
  APARTMENTS_EDIT_ROLE,
  PAYMENTS_VIEW_ROLE,
  BILLS_VIEW_ROLE,
  NOTIFICATIONS_ROLE,
  PAYMENTS_EDIT_ROLE,
} from '../authentication/Roles';

const Actions = ({ row, code, parkingLots, storageUnits, myParkingLots, myStorageUnits, paymentsPlan, bills }) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [openNotify, setOpenNotify] = useState(false);
  const [openStorageUnit, setOpenStorageUnit] = useState(false);
  const [openParkingLot, setOpenParkingLot] = useState(false);
  const [openPaymentsPlan, setOpenPaymentsPlan] = useState(false);
  const [openBills, setOpenBills] = useState(false);
  const [openDeleteInfo, setOpenDeleteInfo] = useState(false);

  const formatedBills = bills
    ? Object.keys(bills).map((x) => {
      const obj = bills[x];
      obj.key = x;
      return obj;
    }).reverse()
    : [];

  const handleChangeAction = (e) => {
    switch (e.target.value) {
      case 'edit':
        setOpenEdit(true);
        break;
      case 'notify':
        setOpenNotify(true);
        break;
      case 'parking_lot':
        setOpenParkingLot(true);
        break;
      case 'storage_unit':
        setOpenStorageUnit(true);
        break;
      case 'payment':
        setOpenPaymentsPlan(true);
        break;
      case 'bills':
        setOpenBills(true);
        break;
      case 'delete_info':
        setOpenDeleteInfo(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Select
        value="none"
        onChange={handleChangeAction}
      >
        <MenuItem disabled value="none">Ver Acciones</MenuItem>
        {userHasAccess(APARTMENTS_EDIT_ROLE) && <MenuItem value="edit">Editar</MenuItem>}
        {userHasAccess(NOTIFICATIONS_ROLE) && <MenuItem value="notify">Enviar Notificación</MenuItem>}
        {userHasAccess(APARTMENTS_EDIT_ROLE) && <MenuItem value="parking_lot">Añadir Parqueo</MenuItem>}
        {userHasAccess(APARTMENTS_EDIT_ROLE) && <MenuItem value="storage_unit">Añadir Baulera</MenuItem>}
        {userHasAccess(PAYMENTS_VIEW_ROLE) && <MenuItem value="payment">Plan de Pagos</MenuItem>}
        {userHasAccess(BILLS_VIEW_ROLE) && <MenuItem value="bills">Ver comprobantes</MenuItem>}
        {userHasAccess(PAYMENTS_EDIT_ROLE) && <MenuItem value="delete_info">Eliminar Datos</MenuItem>}
      </Select>

      <EditUser open={openEdit} user={row} code={code} handleClose={() => setOpenEdit(false)} />
      <Modal open={openNotify} handleClose={() => setOpenNotify(false)}>
        <Notify code={row} handleClose={() => setOpenNotify(false)} />
      </Modal>
      <Modal open={openParkingLot} handleClose={() => setOpenParkingLot(false)}>
        <SelectParkingLot
          handleClose={() => setOpenParkingLot(false)}
          parkingLots={parkingLots}
          myParkingLots={myParkingLots}
          code={code}
        />
      </Modal>
      <Modal open={openStorageUnit} handleClose={() => setOpenStorageUnit(false)}>
        <SelectStorageUnit
          handleClose={() => setOpenStorageUnit(false)}
          storageUnits={storageUnits}
          myStorageUnits={myStorageUnits}
          code={code}
        />
      </Modal>
      <Modal open={openPaymentsPlan} handleClose={() => setOpenPaymentsPlan(false)}>
        <PaymentsPlan
          handleClose={() => setOpenPaymentsPlan(false)}
          myParkingLots={myParkingLots}
          myStorageUnits={myStorageUnits}
          paymentsPlan={paymentsPlan}
          code={code}
          row={row}
          bills={formatedBills}
        />
      </Modal>
      <Modal open={openBills} handleClose={() => setOpenBills(false)}>
        <Bills
          handleClose={() => setOpenBills(false)}
          bills={formatedBills}
          code={code}
        />
      </Modal>
      <Modal open={openDeleteInfo} handleClose={() => setOpenDeleteInfo(false)}>
        <DeleteApartmentInfo
          handleClose={() => setOpenDeleteInfo(false)}
          apartment={row.apartment}
          owner={row.ownerInfo}
          payments={paymentsPlan}
          parkingLots={myParkingLots}
          storageUnits={myStorageUnits}
          code={code}
          stage={row.stage}
          row={row}
        />
      </Modal>
    </>
  );
};

export default Actions;
