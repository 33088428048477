import firebase from 'firebase';

const logger = (action, data = {}) => {
  const { path } = data;
  delete data.path;
  firebase.database().ref('/logs/').push({
    date: new Date().toISOString(),
    user: firebase.auth().currentUser.email,
    action,
    path,
    data,
  });
};

export default logger;
