import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Select,
  MenuItem,
  Modal,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody } from '@material-ui/core';
import { isNil } from 'lodash';
import PaymentsPlan from './PaymentsPlan';
import { userHasAccess, PAYMENTS_VIEW_ROLE } from '../authentication/Roles';

const PaymentsList = ({ payments }) => {
  const [openPaymentsPlan, setOpenPaymentsPlan] = useState(false);
  const [selectedPaymentIndex, setSelectedPaymentIndex] = useState(0);

  const handleChangeAction = (e, index) => {
    setSelectedPaymentIndex(index);
    switch (e.target.value) {
      case 'payment':
        setOpenPaymentsPlan(true);
        break;
      default:
        break;
    }
  };

  const selectedPayment = payments[selectedPaymentIndex];

  let bills = (selectedPayment && selectedPayment.row.bills) || {};
  bills = Object.keys(bills).map((x) => {
    const obj = bills[x];
    obj.key = x;
    return obj;
  }).reverse();


  return (
    <TableContainer>
      <Table style={{ borderRadius: 10, borderTopLeftRadius: 0, backgroundColor: 'white' }}>
        <TableHead>
          <TableRow>
            <TableCell align="left">Fecha</TableCell>
            <TableCell align="left">Apartamento</TableCell>
            <TableCell align="left">Cliente</TableCell>
            <TableCell align="left">Teléfono</TableCell>
            <TableCell align="left">Monto</TableCell>
            <TableCell align="left">Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.map((payment, index) => (
            <TableRow key={index}>
              <TableCell align="left">{payment.date}</TableCell>
              <TableCell align="left">{payment.apartment}</TableCell>
              <TableCell align="left">{payment.clientName}</TableCell>
              <TableCell align="left">{payment.clientPhone}</TableCell>
              <TableCell align="left">
                {`${payment.totalSubpayments
                  ? `falta ${parseFloat(payment.amount) - payment.totalSubpayments}$ de `
                  : ''}${payment.amount}$`}
              </TableCell>
              <TableCell align="left">
                <Select
                  value="none"
                  onChange={e => handleChangeAction(e, index)}
                >
                  <MenuItem disabled value="none">Ver Acciones</MenuItem>
                  {userHasAccess(PAYMENTS_VIEW_ROLE) && <MenuItem value="payment">Plan de Pagos</MenuItem>}
                </Select>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Modal open={openPaymentsPlan} handleClose={() => setOpenPaymentsPlan(false)}>
        {!isNil(selectedPayment) && (
          <PaymentsPlan
            handleClose={() => setOpenPaymentsPlan(false)}
            myParkingLots={selectedPayment.row.parkingLots}
            myStorageUnits={selectedPayment.row.storageUnits}
            paymentsPlan={selectedPayment.row.payments}
            code={selectedPayment.code}
            row={selectedPayment.row}
            bills={bills}
          />
        )}
      </Modal>
    </TableContainer>
  );
};

const mapStateToProps = state => ({
  apartments: state.data.apartments,
});

export default connect(mapStateToProps, null)(PaymentsList);
