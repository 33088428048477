import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import News from '../components/news/News';
import Apartments from '../components/apartments/Apartments';
import Notifications from '../components/notifications/Notifications';
import Steps from '../components/steps/Steps';
import Payments from '../components/payments/Payments';
import PendingBills from '../components/bills/PendingBills';
import Admin from '../components/app';

function Routes() {
  return (
    <Admin>
      <Switch>
        <Route exact path="/" component={News} />
        <Route exact path="/news" component={News} />
        <Route exact path="/apartments" component={Apartments} />
        <Route exact path="/notifications" component={Notifications} />
        <Route exact path="/steps" component={Steps} />
        <Route exact path="/payments" component={Payments} />
        <Route exact path="/pendingBills" component={PendingBills} />
        <Redirect from="*" to="/news" />
      </Switch>
    </Admin>
  );
}

export default Routes;
