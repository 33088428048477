import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, TextField } from '@material-ui/core';
import firebase from 'firebase';
import PaymentsPlanTable from './PaymentsPlanTable';
import UnprocessedBills from './UnprocessedBills';
import logger from '../../actions/helpers';
import useStyles from '../../theme/Styles';
import { PAYMENTS_EDIT_ROLE, PAYMENTS_PAY_ROLE } from '../authentication/Roles';

const PaymentPlan = ({ row, code, handleClose, bills = [],
  myParkingLots = [], myStorageUnits = [], paymentsPlan = {}, userHasEditPaymentsRole, userHasPayPaymentsRole }) => {
  const classes = useStyles();
  const [apartmentPrice, setApartmentPrice] = useState(paymentsPlan.apartmentPrice || 68900);
  const [parkingLotsPrice, setParkingLotsPrice] = useState(
    mapObjectWithPrices(myParkingLots, paymentsPlan.parkingLotsPrice, 10000),
  );
  const [storageUnitsPrice, setStorageUnitsPrice] = useState(
    mapObjectWithPrices(myStorageUnits, paymentsPlan.storageUnitsPrice, 10000),
  );
  const totalAmount = parseFloat(apartmentPrice)
    + parkingLotsPrice.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    + storageUnitsPrice.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
  const today = new Date();
  const [payments, setPayments] = useState(paymentsPlan.payments || {
    // 1: {
    //   programmedDate: today.toISOString(),
    //   amount: 0,
    //   payedDate: '',
    // },
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const pushToFirebase = () => {
    setLoading(true);
    const finalPaymentsPlan = {
      apartmentPrice,
      parkingLotsPrice: myParkingLots.map((pklot, index) => ({ id: pklot, price: parkingLotsPrice[index] })),
      storageUnitsPrice: myStorageUnits.map((storage, index) => ({ id: storage, price: storageUnitsPrice[index] })),
      payments,
    };
    firebase.database().ref(`/codes/${code}/payments`).set(finalPaymentsPlan)
      .then(() => {
        setLoading(false);
        handleClose();
      });
    logger('PaymentPlan/pushToFirebase', { path: `/codes/${code}/payments`, finalPaymentsPlan });
  };

  let totalPayed = 0;
  Object.keys(payments).forEach((paymentId) => {
    if (paymentId) {
      totalPayed += parseFloat(payments[paymentId].amount);
    }
  });

  const saveDisabled = totalPayed / totalAmount !== 1 && (totalPayed !== 0 || totalAmount !== 0);

  return (
    <div className={classes.modalContainer}>
      <span className={classes.subTitle}>Plan de Pagos</span>
      <hr />
      <div container className={classes.selectModalContainer}>
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 40 }}>
          <div className={classes.paymentFormRow}>
            <div className={classes.paymentText}>{`Departamento ${row.apartment}`}</div>
            <TextField
              value={apartmentPrice}
              onChange={(e) => { setApartmentPrice(e.target.value); }}
              margin="normal"
              disabled={!userHasEditPaymentsRole}
              type="numeric"
              inputProps={{
                className: classes.inputFieldForm,
              }}
            />
          </div>
          {myParkingLots.map((parking, index) => (
            <div className={classes.paymentFormRow}>
              <div className={classes.paymentText}>{`Parqueo ${parking}`}</div>
              <TextField
                value={parkingLotsPrice[index]}
                disabled={!userHasEditPaymentsRole}
                onChange={(e) => {
                  const newPrice = [...parkingLotsPrice];
                  newPrice[index] = e.target.value;
                  setParkingLotsPrice(newPrice);
                }}
                margin="normal"
                type="numeric"
                inputProps={{
                  className: classes.inputFieldForm,
                }}
              />
            </div>
          ))}
          {myStorageUnits.map((storage, index) => (
            <div className={classes.paymentFormRow}>
              <div className={classes.paymentText}>{`Baulera ${storage}`}</div>
              <TextField
                value={storageUnitsPrice[index]}
                disabled={!userHasEditPaymentsRole}
                onChange={(e) => {
                  const newPrice = [...storageUnitsPrice];
                  newPrice[index] = e.target.value;
                  setStorageUnitsPrice(newPrice);
                }}
                margin="normal"
                type="numeric"
                inputProps={{
                  className: classes.inputFieldForm,
                }}
              />
            </div>
          ))}
          <div className={classes.paymentFormRow} style={{ borderTop: '1 white solid' }}>
            <span className={classes.paymentTotalText}>Total</span>
            <span className={classes.paymentTotalText}>
              {totalAmount}
            </span>
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 40 }}>
          <PaymentsPlanTable
            payments={payments}
            setPayments={setPayments}
            total={totalAmount}
            errors={errors}
            setErrors={setErrors}
            userHasEditPaymentsRole={userHasEditPaymentsRole}
            userHasPayPaymentsRole={userHasPayPaymentsRole}
          />
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 40 }}>
          <UnprocessedBills
            bills={bills.filter(bill => bill.processed === false)}
            code={code}
          />
        </div>

      </div>
      {
        (userHasEditPaymentsRole || userHasPayPaymentsRole) && (
          <Button
            disabled={saveDisabled || loading || errors}
            variant="outlined"
            color="primary"
            className={!loading && !saveDisabled ? classes.button : classes.buttonDisabled}
            onClick={pushToFirebase}
          >
              Guardar
          </Button>
        )
      }
      <Button
        color="primary"
        className={!loading ? classes.button : classes.buttonDisabled}
        onClick={handleClose}
        disabled={loading}
      >
        Cancelar
      </Button>
    </div>
  );
};

const mapObjectWithPrices = (units = [], prices = [], defaultPrice) => {
  const newPrices = [];
  units.forEach((unit) => {
    const price = prices.find(p => p.id === unit);
    newPrices.push(price ? price.price : defaultPrice);
  });
  return newPrices;
};

const mapStateToProps = state => ({
  userHasEditPaymentsRole: state.user.account.roles.includes(PAYMENTS_EDIT_ROLE),
  userHasPayPaymentsRole: state.user.account.roles.includes(PAYMENTS_PAY_ROLE),
});

export default connect(mapStateToProps, null)(PaymentPlan);
