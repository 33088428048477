import React, { useState } from 'react';
import { Checkbox, Grid, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import useStyles from '../../theme/Styles';
import Stages from '../common/Stages';

const NotifyTo = ({ codes, setSelectedCodes }) => {
  const [notifyTo, setNotifyTo] = useState('all');
  const [codeValues, setCodeValues] = useState([]);
  const [stageValues, setStageValues] = useState([]);

  const classes = useStyles();
  const CustomCheckbox = withStyles({
    root: {
      color: 'white',
    },
    checked: {},
  })(props => <Checkbox color="default" {...props} />);

  const CustomRadio = withStyles({
    root: {
      color: 'white',
    },
    checked: {},
  })(props => <Radio color="default" {...props} />);

  const codesCheckboxes = Object.keys(codes).map(code => (
    <Grid item xs={2}>
      <CustomCheckbox
        checked={codeValues.includes(code)}
        onChange={(e) => {
          let newValues;
          if (!codeValues.includes(e.target.value)) newValues = codeValues.concat([e.target.value]);
          else {
            newValues = codeValues.filter(c => e.target.value !== c);
          }
          setCodeValues(newValues);
          setSelectedCodes(newValues);
        }}
        value={code}
      />
      <span style={{ color: 'white', whiteSpace: 'nowrap' }}>{code.replace(/\D/g, '')}</span>
    </Grid>
  ));

  const stagesCheckboxes = Stages.map(stage => (
    <Grid item xs={2}>
      <CustomCheckbox
        checked={stageValues.includes(stage.value)}
        onChange={() => {
          let stageValuesCopy = stageValues;
          if (stageValues.includes(stage.value)) {
            setStageValues(stageValues.filter(x => x !== stage.value));
            stageValuesCopy = stageValues.filter(x => x !== stage.value);
          } else {
            setStageValues(stageValues.concat([stage.value]));
            stageValuesCopy = stageValues.concat([stage.value]);
          }
          let newValues = [];
          stageValuesCopy.forEach((x) => {
            newValues = newValues.concat(Object.keys(codes).filter(c => codes[c].stage === x));
          });
          setSelectedCodes(newValues);
        }}
        value={stage.value}
      />
      <span style={{ color: 'white', whiteSpace: 'nowrap' }}>{stage.name}</span>
    </Grid>
  ));
  return (
    <div className={classes.selectCodesContainer}>
      <RadioGroup
        aria-label="notifyTo"
        name="NotifyTo"
        value={notifyTo}
        onChange={(e) => {
          setNotifyTo(e.target.value);
          if (e.target.value === 'all') setSelectedCodes(Object.keys(codes));
          else {
            setSelectedCodes([]);
            setCodeValues([]);
          }
        }}
      >
        <FormControlLabel
          value="all"
          control={<CustomRadio />}
          label="Notificar a todos"
          labelPlacement="end"
          style={{ color: 'white' }}
        />
        <FormControlLabel
          value="stage"
          control={<CustomRadio />}
          label="Notificar por etapa"
          labelPlacement="end"
          style={{ color: 'white' }}
        />
        <FormControlLabel
          value="code"
          control={<CustomRadio />}
          label="Notificar por departamento"
          labelPlacement="end"
          style={{ color: 'white' }}
        />
      </RadioGroup>
      {notifyTo === 'stage'
        && (
        <Grid container>
          {stagesCheckboxes}
        </Grid>
        )
      }
      {notifyTo === 'code'
        && (
        <Grid container>
          {codesCheckboxes}
        </Grid>
        )
      }
    </div>
  );
};

export default NotifyTo;
