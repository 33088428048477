import React from 'react';
import { connect } from 'react-redux';
import ApartmentsList from './ApartmentsList';

import useStyles from '../../theme/Styles';

const Apartments = ({ apartments, parkingLots, storageUnits }) => {
  const classes = useStyles();
  return (
    <div className={classes.contentContainer}>
      <span className={classes.title}>Departamentos</span>
      <br />
      <br />
      <ApartmentsList
        apartments={apartments}
        parkingLots={parkingLots}
        storageUnits={storageUnits}
      />
    </div>
  );
};


const mapStateToProps = state => ({
  apartments: state.data.apartments,
  parkingLots: state.data.parkingLots,
  storageUnits: state.data.storageUnits,
});


export default connect(mapStateToProps, null)(Apartments);
