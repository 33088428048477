import React from 'react';
import { Button, Grid, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import firebase from 'firebase';
import { connect } from 'react-redux';
import logger from '../../actions/helpers';
import useStyles from '../../theme/Styles';
import { BILLS_EDIT_ROLE } from '../authentication/Roles';

const Bills = ({ bills, code, handleClose, userHasEditBillsRole }) => {
  const classes = useStyles();

  const changeProcessStatus = (key, status) => {
    firebase.database().ref(`/codes/${code}/bills/${key}`).update({
      processed: status,
    });
    logger('Bills/changeProcessStatus', { path: `/codes/${code}/bills/${key}`, processed: status });
  };

  return (
    <div className={classes.modalContainer}>
      <span className={classes.subTitle}>Comprobantes de pago</span>
      <Grid container className={classes.selectModalContainer}>
        {bills.length !== 0
          ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={{ color: 'white' }}>Fecha</TableCell>
                  <TableCell align="left" style={{ color: 'white' }}>Estado</TableCell>
                  <TableCell align="left" style={{ color: 'white' }}>Ver recibo</TableCell>
                  {
                    userHasEditBillsRole && (
                      <TableCell align="left" style={{ color: 'white' }}>Cambiar estado</TableCell>
                    )
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {bills.map((bill) => {
                  const date = new Date(bill.date).toDateString();
                  return (
                    <TableRow>
                      <TableCell align="left" style={{ color: 'white' }}>{date}</TableCell>
                      <TableCell align="left" style={{ color: 'white' }}>
                        {bill.processed ? 'Procesada' : 'No procesada'}
                      </TableCell>
                      <TableCell align="left" style={{ color: 'white' }}>
                        <Button
                          color="primary"
                          className={classes.smbutton}
                          onClick={() => window.open(bill.location, '_blank')}
                        >
                          Ver
                        </Button>
                      </TableCell>
                      {
                        userHasEditBillsRole && (
                          <TableCell align="left" style={{ color: 'white' }}>
                            {
                              bill.processed
                                ? (
                                  <Button
                                    color="primary"
                                    className={classes.smbutton}
                                    onClick={() => changeProcessStatus(bill.key, false)}
                                  >
                                  Cambiar a no procesado
                                  </Button>
                                )
                                : (
                                  <Button
                                    color="primary"
                                    className={classes.smbutton}
                                    onClick={() => changeProcessStatus(bill.key, true)}
                                  >
                                  Procesar
                                  </Button>
                                )
                            }
                          </TableCell>
                        )
                      }
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )
          : (
            <div className={classes.billsText}>
          No hay comprobantes registrados en este departamento.
            </div>
          )
      }
      </Grid>
      <Button
        color="primary"
        className={classes.button}
        onClick={handleClose}
      >
        Cancelar
      </Button>
    </div>
  );
};

const mapStateToProps = state => ({
  userHasEditBillsRole: state.user.account.roles.includes(BILLS_EDIT_ROLE),
});

export default connect(mapStateToProps, null)(Bills);
