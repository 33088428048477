import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import Loading from '../components/authentication/Loader';
import SignIn from '../components/authentication/SignIn';

function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Loading} />
      <Route exact path="/sign_in" component={SignIn} />
      <Redirect from="*" to="/" />
    </Switch>
  );
}

export default Routes;
