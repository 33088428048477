import React, { useState } from 'react';
import { Button, TextField, FormControl } from '@material-ui/core';
import { AddAlert } from '@material-ui/icons';
import firebase from 'firebase';
import logger from '../../actions/helpers';
import NotifyTo from './NotifyTo';

import useStyles from '../../theme/Styles';

const Notifications = ({ codes }) => {
  const classes = useStyles();
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [sendCodes, setSendCodes] = useState(Object.keys(codes));
  const [loading, setLoading] = useState(false);
  const [succeeded, setSucceeded] = useState(false);

  const sendPushNotification = async ({ token, mutedNotification, unreadCount }) => {
    const message = {
      to: token,
      sound: mutedNotification ? null : 'default',
      title,
      body: text,
      badge: unreadCount,
      data: { title, text },
      _displayInForeground: true,
    };
    await fetch('https://exp.host/--/api/v2/push/send', {
      mode: 'no-cors',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Accept-encoding': 'gzip, deflate',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(message),
    });
    return message;
  };

  const onlyUnique = (value, index, self) => self.indexOf(value) === index;

  const createDataToSend = async () => {
    setLoading(true);
    const promises = [];
    let users = [];
    sendCodes.forEach((code) => {
      const full = codes[code];
      if (full.users) {
        Object.keys(full.users).forEach((ukey) => {
          const user = full.users[ukey];
          users.push(user.uid);
        });
      }
    });

    users = users.filter(onlyUnique);
    users.forEach((uid) => {
      promises.push(
        firebase.database().ref(`/notifications/${uid}/`).push({
          text,
          title,
          date: new Date().toISOString(),
          viewed: false,
        })
          .then(() => {
            firebase.database().ref(`users/${uid}/info/`).once('value', (snapshot) => {
              const user = snapshot.val();
              if (user) {
                const { token, notify, mutedNotification } = snapshot.val();
                if (notify) {
                  firebase.database().ref(`/notifications/${uid}/`).once('value', (res) => {
                    let unreadCount = 0;
                    res.forEach((snap) => {
                      const notification = snap.val();
                      if (!notification.viewed) {
                        unreadCount += 1;
                      }
                    });
                    sendPushNotification({ token, mutedNotification, unreadCount });
                  });
                }
              }
            });
          }),
      );
      logger('Notifications/Form/createDataToSend', { path: `/notifications/${uid}/`, text, title, viewed: false });
    });

    Promise.all(promises).then(() => {
      setLoading(false);
      setSucceeded(true);
    });

    if (users.length === 0) {
      setLoading(false);
      setSucceeded(true);
    }
  };

  const setSelectedCodes = (data) => {
    setSendCodes(data);
    setSucceeded(false);
  };

  const canSendForm = (
    (sendCodes.length > 0)
    && title
    && text
    && !loading
  );

  return (
    <div style={{ height: '75vh', overflowY: 'auto' }}>
      <div className={classes.notificationsContainer}>
        <FormControl className={classes.formControlDialogWide}>
          <FormControl className={classes.formControlDialog}>
            <TextField
              label={<span className={classes.inputPlaceholder}>Título</span>}
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                setSucceeded(false);
              }}
              margin="normal"
              InputProps={{
                className: classes.inputField,
              }}
            />
          </FormControl>
          <TextField
            label={<span className={classes.inputPlaceholder}>Texto</span>}
            value={text}
            onChange={(e) => {
              setText(e.target.value);
              setSucceeded(false);
            }}
            margin="normal"
            multiline
            rows={3}
            className={classes.formFieldLarge}
            InputProps={{
              className: classes.inputField,
            }}
          />
        </FormControl>
        <NotifyTo codes={codes} setSelectedCodes={setSelectedCodes} />
      </div>
      <Button
        variant="outlined"
        color="primary"
        className={canSendForm ? classes.button : classes.buttonDisabled}
        onClick={() => createDataToSend()}
        disabled={!canSendForm}
      >
        <AddAlert />
          Enviar
      </Button>
      {succeeded && <p style={{ color: 'green' }}>La notificacion se envio correctamente</p>}
    </div>
  );
};

export default Notifications;
