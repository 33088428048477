import React, { useState } from 'react';
import { Modal, FormControl, TextField, Button, InputLabel, Select, MenuItem } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import firebase from 'firebase';
import logger from '../../actions/helpers';
import useStyles from '../../theme/Styles';

const EditUser = ({ open, user, code, handleClose }) => {
  const classes = useStyles();
  const [name, setName] = useState(user.ownerInfo.name || '');
  const [phone, setPhone] = useState(user.ownerInfo.phone || '');
  const [description, setDescription] = useState(user.description || '');
  const [available, setAvailable] = useState(user.available || 'available');
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    firebase.database().ref(`/codes/${code}/ownerInfo`).set({
      name,
      phone,
      available,
    })
      .then(() => {
        firebase.database().ref(`/codes/${code}`).update({
          available,
          description,
        }).then(() => {
          setLoading(false);
          handleClose();
        });
      });
    logger('EditUser/handleSubmit', { path: `/codes/${code}/ownerInfo`, name, phone, available });
    logger('EditUser/handleSubmit', { path: `/codes/${code}`, available, description });
  };

  const canSendForm = !loading;
  return (
    <Modal open={open} onClose={handleClose}>
      <div className={classes.modalContainer}>
        <span className={classes.subTitle}>Editar Usuario</span>
        <br />
        <FormControl className={classes.formControlDialog}>
          <TextField
            label={<span className={classes.inputPlaceholder}>Nombre</span>}
            value={name}
            onChange={(e) => { setName(e.target.value); }}
            margin="normal"
            InputProps={{
              className: classes.inputField,
            }}
          />
        </FormControl>
        <FormControl className={classes.formControlDialog}>
          <TextField
            label={<span className={classes.inputPlaceholder}>Teléfono</span>}
            value={phone}
            onChange={(e) => { setPhone(e.target.value); }}
            margin="normal"
            InputProps={{
              className: classes.inputField,
            }}
          />
        </FormControl>
        <FormControl component="fieldset" className={classes.formControlDialogSelect}>
          <InputLabel htmlFor="available-select">
            <span className={classes.inputPlaceholder}>Disponibilidad</span>
          </InputLabel>
          <Select
            value={available}
            className={classes.inputFieldSelect}
            inputProps={{
              id: 'available-select',
            }}
            onChange={e => setAvailable(e.target.value)}
          >
            <MenuItem value="available">Disponible</MenuItem>
            <MenuItem value="reserved">Reservado</MenuItem>
            <MenuItem value="sold">Vendido</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label={<span className={classes.inputPlaceholder}>Descripción</span>}
          value={description}
          multiline
          rows={2}
          onChange={(e) => { setDescription(e.target.value); }}
          margin="normal"
          className={classes.inputFieldLarge}
          InputProps={{
            className: classes.inputField,
          }}
        />
        <br />
        <Button
          variant="outlined"
          color="primary"
          className={canSendForm ? classes.buttonModalAddNews : classes.buttonModalAddNewsDisabled}
          onClick={handleSubmit}
          disabled={!canSendForm}
        >
          <Edit />
          Editar
        </Button>
        <Button
          color="primary"
          className={classes.buttonModalCancelAddNews}
          onClick={handleClose}
        >
          Cancelar
        </Button>
      </div>
    </Modal>
  );
};

export default EditUser;
