import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Routes from './routes';
import './App.css';

import configureFirebase from './config/FirebaseConfig';
import store from './config/ConfigStore';
import configureTheme from './config/ThemeConfig';

// This is the method to generate the codes
// import pushToFirebase from './config/GenerateCodesScript';

const App = () => {
  useEffect(() => {
    configureFirebase();
    // This is the method to generate the codes
    // pushToFirebase();
  }, []);

  const theme = configureTheme();
  return (
    <div className="App">
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <Routes />
          </Provider>
        </ThemeProvider>
      </MuiThemeProvider>
    </div>
  );
};

export default App;
