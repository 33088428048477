import React, { useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import firebase from 'firebase';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import logger from '../../actions/helpers';
import useStyles from '../../theme/Styles';

const ChangeDate = ({ handleClose, step, stage }) => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = React.useState(Date.now);
  const [loading, setLoading] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const pushToFirebase = () => {
    setLoading(true);
    firebase.database().ref(`/steps/${stage}/${step}`).update({
      date: selectedDate,
    }).then(() => {
      handleClose();
      setLoading(false);
    })
      .catch(() => setLoading(false));
    logger('ChangeDate/pushToFirebase', { path: `/steps/${stage}/${step}`, date: selectedDate });
  };

  return (
    <div className={classes.smallModalContainer}>
      <span className={classes.subTitle}>Cambiar fecha</span>
      <br />
      <span className={classes.subTitle}>{step}</span>
      <Grid container>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            label={<span className={classes.inputPlaceholder}>Fecha</span>}
            value={selectedDate}
            onChange={handleDateChange}
            InputProps={{
              className: classes.inputField,
            }}
            KeyboardButtonProps={{
              className: classes.inputIcon,
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Button
        variant="outlined"
        color="primary"
        className={!loading ? classes.button : classes.buttonDisabled}
        onClick={pushToFirebase}
        disabled={loading}
      >
        Cambiar
      </Button>
      <Button
        color="primary"
        className={!loading ? classes.button : classes.buttonDisabled}
        onClick={handleClose}
        disabled={loading}
      >
      Cancelar
      </Button>
    </div>
  );
};

export default ChangeDate;
