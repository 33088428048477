import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import { useDropzone } from 'react-dropzone';

import useStyles from '../../theme/Styles';

const DropZone = ({ onFileSelected }) => {
  const classes = useStyles();
  const onDrop = (files) => { onFileSelected(files[0]); };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps()}
      className={classes.dropzoneContainer}
    >
      <input {...getInputProps()} />
      <div className={classes.dropzoneContent}>
        {isDragActive ? (
          <Typography variant="h5">
            Suelta tu foto aquí
          </Typography>
        ) : (
          <div>
            <CloudUpload color="disabled" fontSize="large" className={classes.dropzoneIcon} />
            <div>
              <Typography variant="subtitle1" className={classes.dropzoneText}>
                Arrastra aquí tu foto o
              </Typography>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                className={classes.dropzoneButton}
              >
                Selecciona un archivo
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DropZone;
