import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import NewsList from './NewsList';
import AddNews from './AddNews';
import { userHasAccess, NOTIFICATIONS_ROLE } from '../authentication/Roles';

import useStyles from '../../theme/Styles';

const News = ({ news }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  return (
    <div className={classes.contentContainer}>
      <span className={classes.title}>Noticias</span>
      <br />
      {userHasAccess(NOTIFICATIONS_ROLE) && (
        <Button
          variant="outlined"
          color="primary"
          className={classes.buttonAddNews}
          onClick={() => setOpen(true)}
        >
          <Add />
                Nueva Noticia
        </Button>
      )}
      <br />
      <NewsList news={news} />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
      >
        <div>
          <AddNews handleClose={() => setOpen(false)} />
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  news: state.data.news,
});


export default connect(mapStateToProps, null)(News);
