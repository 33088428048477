import React, { useState } from 'react';
import { Select, MenuItem, Modal } from '@material-ui/core';
import ChangeDate from './ChangeDate';
import ChangeState from './ChangeState';

const Actions = ({ stage, step, completed }) => {
  const [openDateModal, setOpenDateModal] = useState(false);
  const [openStateModal, setOpenStateModal] = useState(false);

  const handleChangeAction = (e) => {
    switch (e.target.value) {
      case 'change_date':
        setOpenDateModal(true);
        break;
      case 'change_state':
        setOpenStateModal(true);
        break;
      default:
        break;
    }
  };
  return (
    <>
      <Select
        value="none"
        onChange={handleChangeAction}
      >
        <MenuItem disabled value="none">Ver Acciones</MenuItem>
        <MenuItem value="change_date">Cambiar fecha</MenuItem>
        <MenuItem value="change_state">Configurar Estado</MenuItem>
      </Select>
      <Modal open={openDateModal} handleClose={() => setOpenDateModal(false)}>
        <ChangeDate
          stage={stage}
          step={step}
          handleClose={() => setOpenDateModal(false)}
        />
      </Modal>
      <Modal open={openStateModal} handleClose={() => setOpenStateModal(false)}>
        <ChangeState
          stage={stage}
          step={step}
          currentState={completed}
          handleClose={() => setOpenStateModal(false)}
        />
      </Modal>
    </>
  );
};

export default Actions;
