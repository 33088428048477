import React from 'react';
import { Button, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import firebase from 'firebase';
import logger from '../../actions/helpers';
import useStyles from '../../theme/Styles';
import { userHasAccess, BILLS_EDIT_ROLE } from '../authentication/Roles';

const UnprocessedBills = ({ bills, code }) => {
  const classes = useStyles();

  const changeProcessStatus = (key, status) => {
    firebase.database().ref(`/codes/${code}/bills/${key}`).update({
      processed: status,
    });
    logger('UnprocessedBills/changeProcessStatus', { path: `/codes/${code}/bills/${key}`, processed: status });
  };


  return (
    <>
      {bills.length !== 0 && (
      <>
        <span className={classes.subTitle}>Comprobantes de pago no procesados</span>
        <Table style={{ backgroundColor: 'white' }}>
          <TableHead>
            <TableRow>
              <TableCell align="left">Fecha</TableCell>
              <TableCell align="left">Ver recibo</TableCell>
              {
                    userHasAccess(BILLS_EDIT_ROLE) && (
                      <TableCell align="left">Cambiar estado</TableCell>
                    )
                  }
            </TableRow>
          </TableHead>
          <TableBody>
            {bills.map((bill) => {
              const date = new Date(bill.date).toDateString();
              return (
                <TableRow>
                  <TableCell align="left">{date}</TableCell>
                  <TableCell align="left">
                    <Button
                      style={{ width: 100 }}
                      onClick={() => window.open(bill.location, '_blank')}
                    >
                          Ver
                    </Button>
                  </TableCell>
                  {
                    userHasAccess(BILLS_EDIT_ROLE) && (
                      <TableCell align="left" style={{ color: 'white' }}>
                        <Button
                          style={{ width: 100 }}
                          onClick={() => changeProcessStatus(bill.key, true)}
                        >
                              Procesar
                        </Button>
                      </TableCell>
                    )
                  }
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

      </>
      )
      }
    </>
  );
};

export default UnprocessedBills;
