import React, { useState } from 'react';
import { Select, MenuItem, Modal } from '@material-ui/core';
import Bills from '../apartments/Bills';
import {
  userHasAccess,
  BILLS_VIEW_ROLE,
} from '../authentication/Roles';

const Actions = ({ code, bills }) => {
  const [openBills, setOpenBills] = useState(false);

  const formatedBills = bills
    ? Object.keys(bills).map((x) => {
      const obj = bills[x];
      obj.key = x;
      return obj;
    }).reverse()
    : [];

  const handleChangeAction = (e) => {
    switch (e.target.value) {
      case 'bills':
        setOpenBills(true);
        break;
      default:
        break;
    }
  };
  return (
    <>
      <Select
        value="none"
        onChange={handleChangeAction}
      >
        <MenuItem disabled value="none">Ver Acciones</MenuItem>
        {userHasAccess(BILLS_VIEW_ROLE) && <MenuItem value="bills">Ver comprobantes</MenuItem>}
      </Select>
      <Modal open={openBills} handleClose={() => setOpenBills(false)}>
        <Bills
          handleClose={() => setOpenBills(false)}
          bills={formatedBills}
          code={code}
        />
      </Modal>
    </>
  );
};

export default Actions;
