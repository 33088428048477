import React, { useState } from 'react';
import { Button, Checkbox, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import firebase from 'firebase';
import logger from '../../actions/helpers';
import useStyles from '../../theme/Styles';

const SelectStorageUnit = ({ code, storageUnits, handleClose, myStorageUnits }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(myStorageUnits || []);
  const [loading, setLoading] = useState(false);

  const CustomCheckbox = withStyles({
    root: {
      color: 'white',
    },
    checked: {},
  })(props => <Checkbox color="default" {...props} />);

  const toShow = Object.keys(storageUnits)
    .filter(x => storageUnits[x].available || (myStorageUnits || []).includes(parseInt(x, 10)))
    .map(x => parseInt(x, 10));

  const toggleCheckbox = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter(x => x !== id));
    } else {
      setSelected(selected.concat([id]));
    }
  };

  const checkboxes = toShow.map(id => (
    <Grid item xs={2}>
      <CustomCheckbox
        checked={selected.includes(id)}
        value={id}
        onChange={() => toggleCheckbox(id)}
      />
      <span style={{ color: 'white', whiteSpace: 'nowrap' }}>{id}</span>
    </Grid>
  ));

  const pushToFirebase = () => {
    setLoading(true);
    const promises = [];
    promises.push(firebase.database().ref(`/codes/${code}/storageUnits`).set(selected.sort()));
    logger('SelectStorageUnit/pushToFirebase', { path: `/codes/${code}/storageUnits`, storageUnits: selected.sort() });
    selected.forEach((id) => {
      promises.push(firebase.database().ref(`/storage_units/${id}`).update({ available: false }));
      logger('SelectStorageUnit/pushToFirebase', { path: `/storage_units/${id}`, available: false });
    });
    (myStorageUnits || []).forEach((id) => {
      if (!selected.includes(parseInt(id, 10))) {
        promises.push(firebase.database().ref(`/storage_units/${id}`).update({ available: true }));
        logger('SelectStorageUnit/pushToFirebase', { path: `/storage_units/${id}`, available: true });
      }
    });

    Promise.all(promises).then(() => {
      setLoading(false);
      handleClose();
    });
  };

  return (
    <div className={classes.modalContainer}>
      <span className={classes.subTitle}>Añadir Baulera</span>
      <Grid container className={classes.selectModalContainer}>
        {checkboxes}
      </Grid>
      <Button
        variant="outlined"
        color="primary"
        className={!loading ? classes.button : classes.buttonDisabled}
        onClick={pushToFirebase}
        disabled={loading}
      >
          Añadir
      </Button>
      <Button
        color="primary"
        className={!loading ? classes.button : classes.buttonDisabled}
        onClick={handleClose}
        disabled={loading}
      >
        Cancelar
      </Button>
    </div>
  );
};

export default SelectStorageUnit;
