import firebase from 'firebase';
import { DateTime } from 'luxon';
import { LOAD_APARTMENTS, LOAD_STORAGE_UNITS, LOAD_PARKING_LOTS, LOAD_NEWS, LOAD_STEPS } from './types';

export const loadApartments = success => (
  (dispatch) => {
    firebase.database().ref('/codes').on('value', (snapshot) => {
      const apartments = snapshot.val() || {};
      dispatch({ type: LOAD_APARTMENTS, payload: { apartments } });
      success();
    });
  });

export const loadStorageUnits = success => (
  (dispatch) => {
    firebase.database().ref('/storage_units').on('value', (snapshot) => {
      const storageUnits = snapshot.val() || {};
      dispatch({ type: LOAD_STORAGE_UNITS, payload: { storageUnits } });
      success();
    });
  });

export const loadParkingLots = success => (
  (dispatch) => {
    firebase.database().ref('/parking_lots').on('value', (snapshot) => {
      const parkingLots = snapshot.val() || {};
      dispatch({ type: LOAD_PARKING_LOTS, payload: { parkingLots } });
      success();
    });
  });

export const loadNews = success => (
  (dispatch) => {
    firebase.database().ref('/news').on('value', (snapshot) => {
      const data = snapshot.val() || {};
      const news = sortObjByDate(data);
      dispatch({ type: LOAD_NEWS, payload: { news } });
      success();
    });
  });

export const loadSteps = success => (
  (dispatch) => {
    firebase.database().ref('/steps').on('value', (snapshot) => {
      const steps = snapshot.val() || {};
      dispatch({ type: LOAD_STEPS, payload: { steps } });
      success();
    });
  });

const sortObjByDate = (obj) => {
  let arry = [];
  Object.keys(obj).forEach(key => arry.push(obj[key]));
  arry = arry.sort((a, b) => (
    DateTime.fromFormat(b.createdAt, 'dd-MM-yyyy').toMillis()
    - DateTime.fromFormat(a.createdAt, 'dd-MM-yyyy').toMillis()
  ));
  return arry.reverse();
};
