import firebase from 'firebase';

export default () => {
  firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,

    // DEV KEYS
    // apiKey: 'AIzaSyDo4ZV7-Nw68BT4lpR03RHkA-yXmlGoCZ0',
    // authDomain: 'trii-test.firebaseapp.com',
    // databaseURL: 'https://trii-test.firebaseio.com',
    // projectId: 'trii-test',
    // storageBucket: '',
    // messagingSenderId: '735549071235',
    // appId: '1:735549071235:web:39e5c9a3c42df8d2',
    // // admin@triii.com.bo / *Triii*2019*
  });
};
