import React, { useState } from 'react';
import { Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button } from '@material-ui/core';
import Actions from './Actions';
import Accounts from './Accounts';
import Stages from '../common/Stages';

import useStyles from '../../theme/Styles';

const ApartmentsList = ({ apartments, parkingLots, storageUnits }) => {
  const classes = useStyles();
  const [stage, setStage] = useState(1);
  const filteredApartments = [];
  Object.keys(apartments).forEach((key) => {
    const apartment = { ...apartments[key], code: key };
    switch (stage) {
      case 1:
        if (apartment.stage === 1) filteredApartments.push(apartment);
        break;
      case 2:
        if (apartment.stage === 2) filteredApartments.push(apartment);
        break;
      case 3:
        if (apartment.stage === 3) filteredApartments.push(apartment);
        break;
      case 4:
        if (apartment.stage === 4) filteredApartments.push(apartment);
        break;
      default:
        filteredApartments.push(apartment);
    }
  });
  filteredApartments.sort((a, b) => (a.apartment - b.apartment));
  return (
    <div className={classes.newsContainer} style={{ display: 'flex', overflowY: 'none' }}>
      <div style={{ position: 'absolute', marginTop: -31 }}>
        {Stages.map(item => (
          <Button
            className={stage === item.value ? classes.tabButtonsSelected : classes.tabButtons}
            onClick={() => setStage(item.value)}
          >
            {item.name}
          </Button>
        ))}
      </div>
      <TableContainer style={{ height: '100%', overflowY: 'auto', width: 'calc(100% - 40px)' }}>
        <Table stickyHeader style={{ borderRadius: 10, borderTopLeftRadius: 0, backgroundColor: 'white' }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ zIndex: 3 }} align="left">Id</TableCell>
              <TableCell align="left">Dpto</TableCell>
              <TableCell align="left">Desc</TableCell>
              <TableCell align="left">Disponible</TableCell>
              <TableCell align="left">Nombre</TableCell>
              <TableCell align="left">Telefono</TableCell>
              <TableCell align="left">Usuarios APP</TableCell>
              <TableCell align="left">Comprobantes</TableCell>
              <TableCell align="left">Parqueos</TableCell>
              <TableCell align="left">Bauleras</TableCell>
              <TableCell align="left">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredApartments.map((apartment) => {
              const row = { ...apartment,
                ownerInfo: apartment.ownerInfo || {},
                apartments: apartment.apartments || {} };
              return (
                <TableRow key={apartment.code}>
                  <TableCell
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                    className={classes.tableColumnStatic}
                    align="left"
                  >
                    {apartment.code}
                  </TableCell>
                  <TableCell
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                    align="left"
                  >
                    {row.apartment}
                  </TableCell>
                  <TableCell
                    style={{
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                    }}
                    align="left"
                  >
                    {row.description}
                  </TableCell>
                  <TableCell
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                    align="left"
                  >
                    {row.available === 'available' && 'Disponible'}
                    {row.available === 'reserved' && 'Reservado'}
                    {row.available === 'sold' && 'Vendido'}
                  </TableCell>
                  <TableCell align="left">{row.ownerInfo.name}</TableCell>
                  <TableCell align="left">{row.ownerInfo.phone}</TableCell>
                  <TableCell align="left"><Accounts accounts={row.users || {}} row={row} /></TableCell>
                  <TableCell align="left">
                    Sin proc.:
                    {row.bills ? Object.keys(row.bills).filter(k => !row.bills[k].processed).length : 0}
                    <br />
                    Proc.:
                    {row.bills ? Object.keys(row.bills).filter(k => row.bills[k].processed).length : 0}
                  </TableCell>
                  <TableCell align="left">{row.parkingLots ? row.parkingLots.join(',') : ''}</TableCell>
                  <TableCell align="left">{row.storageUnits ? row.storageUnits.join(',') : ''}</TableCell>
                  <TableCell align="left">
                    <Actions
                      row={row}
                      code={row.code}
                      bills={row.bills}
                      parkingLots={parkingLots}
                      storageUnits={storageUnits}
                      myParkingLots={row.parkingLots}
                      myStorageUnits={row.storageUnits}
                      paymentsPlan={row.payments}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ApartmentsList;
