import React from 'react';
import { connect } from 'react-redux';
import Form from './Form';

import useStyles from '../../theme/Styles';

const Notifications = ({ apartments }) => {
  const classes = useStyles();

  return (
    <div className={classes.contentContainer}>
      <span className={classes.title}>Notificaciones</span>
      <Form codes={apartments} />
    </div>
  );
};

const mapStateToProps = state => ({
  apartments: state.data.apartments,
});


export default connect(mapStateToProps, null)(Notifications);
