import React, { useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import firebase from 'firebase';
import { connect } from 'react-redux';
import { Delete } from '@material-ui/icons';
import logger from '../../actions/helpers';
import useStyles from '../../theme/Styles';
import { PAYMENTS_EDIT_ROLE } from '../authentication/Roles';

const DeleteApartmentInfo = ({
  code,
  apartment,
  owner,
  payments,
  parkingLots,
  storageUnits,
  stage,
  row,
  handleClose,
  userHasDeleteInfoRole,
}) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const deleteInfo = () => {
    setLoading(true);
    firebase.database().ref(`/codes/${code}`).set({
      apartment,
      available: true,
      description: '',
      stage,
    })
      .then(() => {
        if (parkingLots) {
          parkingLots.forEach(p => firebase.database().ref(`/parking_lots/${p}`).set({ available: true }));
        }
        if (storageUnits) {
          storageUnits.forEach(s => firebase.database().ref(`/storage_units/${s}`).set({ available: true }));
        }
        handleClose();
        logger('Apartment/deleteAllInfo', { path: `/codes/${code}`, oldData: row });
      });
  };

  return (
    <>
      {userHasDeleteInfoRole && (
        <div className={classes.modalContainer}>
          <span className={classes.subTitle}>Eliminar información departamento</span>
          <div className={classes.billsText}>
            Se eliminará la siguiente información:
          </div>
          <Grid container className={classes.selectModalContainer}>
            <div className={classes.billsText}>
              <ul>
                <li>{`Departamento: ${apartment}`}</li>
                <li>{`Propietario: ${owner.name || ''} / Telf.: ${owner.phone || ''}`}</li>
                <li>{`Plan de pagos: ${payments && payments.payments ? payments.payments.length + ' cuotas' : 'Ninguno'}`}</li>
                <li>{`Parqueos: ${parkingLots ? parkingLots.join(', ') : 'Ninguno'}`}</li>
                <li>{`Bauleras: ${storageUnits ? storageUnits.join(', ') : 'Ninguna'}`}</li>
              </ul>
            </div>
          </Grid>
          <div className={classes.subTitle}>Está seguro que desea eliminar?</div>
          <Button
            variant="outlined"
            color="primary"
            className={loading ? classes.buttonModalAddNewsDisabled : classes.buttonModalAddNews}
            onClick={deleteInfo}
            disabled={loading}
          >
            <Delete />
            &nbsp;Eliminar
          </Button>
          <Button
            color="primary"
            className={classes.buttonModalCancelAddNews}
            onClick={handleClose}
          >
            Cancelar
          </Button>
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  userHasDeleteInfoRole: state.user.account.roles.includes(PAYMENTS_EDIT_ROLE),
});

export default connect(mapStateToProps, null)(DeleteApartmentInfo);
