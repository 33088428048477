import S3 from 'aws-s3';
import { isEmpty } from 'lodash';

const config = {
  bucketName: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
  region: process.env.REACT_APP_AWS_S3_REGION,
  accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY,
  s3Url: process.env.REACT_APP_AWS_S3_URL,
};

const getS3Client = (dirName) => {
  if (!isEmpty(dirName)) {
    config.dirName = dirName;
  }

  return new S3(config);
};

export default getS3Client;
