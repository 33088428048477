import React, { useState } from 'react';
import { Button, TextField, FormControl } from '@material-ui/core';
import { AddAlert } from '@material-ui/icons';
import firebase from 'firebase';
import logger from '../../actions/helpers';

import useStyles from '../../theme/Styles';

const Notifications = ({ code, handleClose }) => {
  const classes = useStyles();
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [succeeded, setSucceeded] = useState(false);

  const onlyUnique = (value, index, self) => self.indexOf(value) === index;

  const createDataToSend = async () => {
    setLoading(true);
    const promises = [];
    let users = [];
    if (code.users) {
      Object.keys(code.users).forEach((ukey) => {
        const user = code.users[ukey];
        users.push(user.uid);
      });
    }

    users = users.filter(onlyUnique);
    users.forEach((uid) => {
      promises.push(firebase.database().ref(`/notifications/${uid}/`).push({
        text,
        title,
        viewed: false,
      }));
      logger('Notifications/SimpleForm/createDataToSend', { path: `/notifications/${uid}/`,
        text,
        title,
        viewed: false });
    });

    Promise.all(promises).then(() => {
      setLoading(false);
      setSucceeded(true);
      setTimeout(handleClose, 2000);
    });

    if (users.length === 0) {
      setLoading(false);
      setSucceeded(true);
    }
  };

  const canSendForm = (
    title
    && text
    && !loading
  );

  return (
    <div className={classes.modalContainer}>
      <span className={classes.subTitle}>Enviar Notificación</span>
      <br />
      <FormControl className={classes.formControlDialog}>
        <TextField
          label={<span className={classes.inputPlaceholder}>Título</span>}
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
            setSucceeded(false);
          }}
          margin="normal"
          InputProps={{
            className: classes.inputField,
          }}
        />
        <TextField
          label={<span className={classes.inputPlaceholder}>Texto</span>}
          value={text}
          onChange={(e) => {
            setText(e.target.value);
            setSucceeded(false);
          }}
          margin="normal"
          multiline
          rows={3}
          className={classes.formFieldLarge}
          InputProps={{
            className: classes.inputField,
          }}
        />
      </FormControl>
      <br />
      <Button
        variant="outlined"
        color="primary"
        className={canSendForm ? classes.buttonModalAddNews : classes.buttonModalAddNewsDisabled}
        onClick={createDataToSend}
        disabled={!canSendForm}
      >
        <AddAlert />
          Enviar
      </Button>
      <Button
        color="primary"
        className={classes.buttonModalCancelAddNews}
        onClick={handleClose}
      >
        Cancelar
      </Button>
      {succeeded && <p style={{ color: 'green' }}>La notificacion se envio correctamente</p>}
    </div>
  );
};

export default Notifications;
