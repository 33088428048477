import React from 'react';
import { Tabs, Tab, Card, CardContent } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import firebase from 'firebase';
import { connect } from 'react-redux';
import { logout } from '../../actions/UserActions';
import {
  userHasAccess,
  NEWS_ROLE,
  APARTMENTS_VIEW_ROLE,
  NEXT_PAYMENTS_ROLE,
  STEPS_EDIT,
  NOTIFICATIONS_ROLE,
  BILLS_VIEW_ROLE,
} from '../authentication/Roles';

import useStyles from '../../theme/Styles';

const Admin = ({ location, children, logoutAction }) => {
  const classes = useStyles();

  let newPathName = '/news';
  if (location.pathname.includes('/news')) {
    newPathName = '/news';
  }
  if (location.pathname.includes('/apartments')) {
    newPathName = '/apartments';
  }
  if (location.pathname.includes('/notifications')) {
    newPathName = '/notifications';
  }

  if (location.pathname.includes('/steps')) {
    newPathName = '/steps';
  }

  if (location.pathname.includes('/payments')) {
    newPathName = '/payments';
  }

  if (location.pathname.includes('/pendingBills')) {
    newPathName = '/pendingBills';
  }

  const signOut = () => {
    logoutAction();
    firebase.auth().signOut();
  };

  return (
    <Card className={classes.cardContainer}>
      <CardContent>
        <div className={classes.verticalTabsContainer}>
          <Tabs
            value={newPathName}
            classes={{
              indicator: classes.verticalTabsTabIndicator,
              flexContainer: classes.verticalTabsFlex,
            }}
            className={classes.verticalTabs}
          >
            {userHasAccess(NEWS_ROLE) && (
              <Tab
                label={<span className={classes.tabLabel}>Noticias</span>}
                classes={{
                  selected: classes.verticalTabsTabSelected,
                }}
                className={classes.verticalTabsTab}
                component={Link}
                to="/news"
                value="/news"
              />
            )}
            {userHasAccess(APARTMENTS_VIEW_ROLE) && (
              <Tab
                label={<span className={classes.tabLabel}>Departamentos</span>}
                classes={{
                  selected: classes.verticalTabsTabSelected,
                }}
                className={classes.verticalTabsTab}
                component={Link}
                to="/apartments"
                value="/apartments"
              />
            )}
            {userHasAccess(NOTIFICATIONS_ROLE) && (
              <Tab
                label={<span className={classes.tabLabel}>Notificaciones</span>}
                classes={{
                  selected: classes.verticalTabsTabSelected,
                }}
                className={classes.verticalTabsTab}
                component={Link}
                to="/notifications"
                value="/notifications"
              />
            )}
            {userHasAccess(STEPS_EDIT) && (
              <Tab
                label={<span className={classes.tabLabel}>Etapas</span>}
                classes={{
                  selected: classes.verticalTabsTabSelected,
                }}
                className={classes.verticalTabsTab}
                component={Link}
                to="/steps"
                value="/steps"
              />
            )}
            {userHasAccess(NEXT_PAYMENTS_ROLE) && (
              <Tab
                label={<span className={classes.tabLabel}>Pagos</span>}
                classes={{
                  selected: classes.verticalTabsTabSelected,
                }}
                className={classes.verticalTabsTab}
                component={Link}
                to="/payments"
                value="/payments"
              />
            )}
            {userHasAccess(BILLS_VIEW_ROLE) && (
              <Tab
                label={<span className={classes.tabLabel}>Comprobantes</span>}
                classes={{
                  selected: classes.verticalTabsTabSelected,
                }}
                className={classes.verticalTabsTab}
                component={Link}
                to="/pendingBills"
                value="/pendingBills"
              />
            )}
            <Tab
              label={<span className={classes.tabLabel}>Cerrar Sesion</span>}
              classes={{
                selected: classes.verticalTabsTabSelected,
              }}
              className={classes.verticalTabsTab}
              onClick={() => signOut()}
            />
          </Tabs>
          <div className={classes.tabInfoContainer}>
            <TabContainer>
              { children }
            </TabContainer>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const mapDispatchToProps = {
  logoutAction: logout,
};

export default connect(null, mapDispatchToProps)(withRouter(Admin));

function TabContainer(props) {
  const { children } = props;
  return (
    <Typography component="div">
      { children }
    </Typography>
  );
}
