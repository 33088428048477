import React, { useState, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Grid, Slider } from '@material-ui/core';
import { PhotoSizeSelectLarge, ZoomIn, ZoomOut } from '@material-ui/icons';
import Cropper from 'react-easy-crop';

import getCroppedImg from './helpers';
import useStyles from '../../../theme/Styles';

const LANDSCAPE = 2.4 / 1;

const CropImage = ({ filePath, open, onCloseModal, onImageCropped, t, ...other }) => {
  const classes = useStyles();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixelsArg) => {
    setCroppedAreaPixels(croppedAreaPixelsArg);
  }, []);

  const getCroppedImage = async () => {
    const croppedImage = await getCroppedImg(filePath, croppedAreaPixels);
    onImageCropped(croppedImage);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      aria-labelledby="new-post-dialog"
      {...other}
    >
    <div className={classes.cropContainer}>
      <DialogTitle>
        <PhotoSizeSelectLarge className={classes.iconLeft} />
        Recortar imagen
      </DialogTitle>
      <DialogContent>
        <div className={classes.cropContentContainer}>
          <Cropper
            image={filePath}
            crop={crop}
            zoom={zoom}
            aspect={LANDSCAPE}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <Grid container className={classes.cropActions}>
          <Grid item md={8} xs={12} className={classes.cropSliderContainer}>
            <IconButton
              className={classes.cropSliderIcon}
              onClick={() => setZoom(zoom - 0.25)}
              aria-label="Zoom Out"
            >
              <ZoomOut />
            </IconButton>
            <Slider
              className={classes.cropSlider}
              value={zoom}
              aria-labelledby="label"
              min={1}
              max={3}
              onChange={(e, value) => setZoom(value)}
            />
            <IconButton
              className={classes.cropSliderIcon}
              onClick={() => setZoom(zoom + 0.25)}
              aria-label="Zoom In"
            >
              <ZoomIn />
            </IconButton>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseModal} className={classes.cropButton}>
          Cancelar
        </Button>
        <Button onClick={getCroppedImage} variant="outlined" color="primary" className={classes.cropButton} autoFocus>
          Seleccionar
        </Button>
      </DialogActions>
      </div>
    </Dialog>
  );
};

export default CropImage;
