import React from 'react';
import { Select, MenuItem } from '@material-ui/core';

const Accounts = ({ accounts }) => {
  const accountsLength = Object.keys(accounts).length;
  let title = `${accountsLength} Usuarios`;
  if (accountsLength > 1) title = `Ver ${accountsLength} Usuarios`;
  else if (accountsLength === 1) title = `Ver ${accountsLength} Usuario`;
  return (
    <Select
      value="none"
      disabled={accountsLength === 0}
    >
      <MenuItem disabled value="none">
        {title}
      </MenuItem>
      {Object.keys(accounts).map(key => (
        <MenuItem value={accounts[key]}>{accounts[key].email}</MenuItem>
      ))}
    </Select>
  );
};

export default Accounts;
