import React, { useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import firebase from 'firebase';
import { connect } from 'react-redux';
import { login, logout, loadApartments, loadStorageUnits, loadParkingLots, loadNews, loadSteps } from '../../actions';
import logger from '../../actions/helpers';
import useStyles from '../../theme/Styles';
import { getUserAccount } from './Roles';

const Loader = ({ loginAction, logoutAction, loadApartmentsAction, loadNewsAction,
  loadParkingLotsAction, loadStorageUnitsAction, loadStepsAction, history }) => {
  const classes = useStyles();

  const loadInitial = () => {
    setTimeout(() => {
      try {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            // Parameter is succeed function
            loadApartmentsAction(() => {
              loadParkingLotsAction(() => {
                loadStorageUnitsAction(() => {
                  loadNewsAction(() => {
                    loadStepsAction(() => loginAction(getUserAccount(user.email)));
                  });
                });
              });
            });
            // logger('user logged in');
          } else {
            // logger('user unable to log in');
            logoutAction();
            history.push('/sign_in');
          }
        });
      } catch (e) {
        // logger('user unable to log in');
        logoutAction();
        history.push('/sign_in');
      }
    }, 1000);
  };

  useEffect(loadInitial, []);

  return (
    <div className={classes.contentContainer}>
      <CircularProgress className={classes.initialLoader} style={{ colorPrimary: { background: 'white' } }} />
    </div>
  );
};

const mapDispatchToProps = {
  loginAction: login,
  logoutAction: logout,
  loadApartmentsAction: loadApartments,
  loadParkingLotsAction: loadParkingLots,
  loadStorageUnitsAction: loadStorageUnits,
  loadNewsAction: loadNews,
  loadStepsAction: loadSteps,
};

export default connect(null, mapDispatchToProps)(Loader);
