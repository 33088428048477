const tintColor = '#2f95dc';

export default {
  backgroundColor: '#141414',
  lightBackgroundColor: '#727272',
  buttonColor: 'white',
  DisabledButtonColor: 'grey',
  DisabledButtonText: '#303030',
  textColor: 'white',
  tabNavigatorColor: 'black',
  tabIconDefault: '#8f8f8f',
  tabIconSelected: 'white',
  notificationBackgroundLight: '#e3e4e6',
  notificationBackgroundDark: '#3d3d3d',
  notificationTextColorDark: 'white',
  settingsText: '#78787a',
  settingsUnderline: '#78787a',
  errorText: '#c4433f',
  tintColor,
  tabBar: '#fefefe',
  errorBackground: 'red',
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  noticeBackground: tintColor,
  noticeText: '#fff',
  white: 'white',
  gray: '#34383c',
  lightGrey: '#B0B0B0',
  lightGreyDisabled: '#3e3e3ebd',
  lightRed: '#642425',
};
