import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Unauthenticated from './InitialFlow';
import Authenticated from './AuthenticatedFlow';

function Routes({ user }) {
  return (
    <BrowserRouter>
      { user.loggedIn
        ? <Authenticated />
        : <Unauthenticated />
      }
    </BrowserRouter>
  );
}

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(Routes);
