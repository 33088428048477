import {
  LOAD_APARTMENTS,
  LOAD_STORAGE_UNITS,
  LOAD_PARKING_LOTS,
  LOAD_NEWS,
  LOAD_STEPS,
} from '../actions/types';

const INITIAL_STATE = {
  apartments: {},
  parkingLots: {},
  storageUnits: {},
  news: {},
  steps: {},
};

export default (state = INITIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case LOAD_APARTMENTS:
      return { ...state, apartments: payload.apartments };
    case LOAD_STORAGE_UNITS:
      return { ...state, storageUnits: payload.storageUnits };
    case LOAD_PARKING_LOTS:
      return { ...state, parkingLots: payload.parkingLots };
    case LOAD_NEWS:
      return { ...state, news: payload.news };
    case LOAD_STEPS:
      return { ...state, steps: payload.steps };
    default:
      return state;
  }
};
