import React from 'react';
import { connect } from 'react-redux';
import PaymentsList from './PaymentsList';
import useStyles from '../../theme/Styles';

const Payments = ({ apartments }) => {
  const classes = useStyles();
  const [latePayments, nextPayments] = collectAllPayemtsFromApartments(apartments);
  return (
    <div className={classes.contentContainer}>
      <div className={classes.newsContainer}>
        <span className={classes.title}>Pagos Vencidos</span>
        <PaymentsList payments={latePayments.sort((a, b) => {
          const dateParts1 = a.date.split('/');
          const dateParts2 = b.date.split('/');
          const date1 = new Date(+dateParts1[2], dateParts1[1] - 1, +dateParts1[0]);
          const date2 = new Date(+dateParts2[2], dateParts2[1] - 1, +dateParts2[0]);
          return (date1 - date2);
        })}
        />
        <span className={classes.title}>Pagos Próximos</span>
        <PaymentsList payments={nextPayments.sort((a, b) => {
          const dateParts1 = a.date.split('/');
          const dateParts2 = b.date.split('/');
          const date1 = new Date(+dateParts1[2], dateParts1[1] - 1, +dateParts1[0]);
          const date2 = new Date(+dateParts2[2], dateParts2[1] - 1, +dateParts2[0]);
          return (date1 - date2);
        })}
        />
      </div>
    </div>
  );
};

const collectAllPayemtsFromApartments = (apartments) => {
  const latePayments = [];
  const nextPayments = [];
  const paidPayments = [];
  Object.keys(apartments).forEach((code) => {
    const { payments } = apartments[code];
    if (payments && payments.payments) {
      const today = new Date();
      Object.keys(payments.payments).forEach((key) => {
        const payment = payments.payments[key];
        const programmedDate = new Date(payment.programmedDate);
        const client = apartments[code].ownerInfo || {};
        const cleanDate = `${programmedDate.getDate()}
        /${programmedDate.getMonth() + 1}
        /${programmedDate.getFullYear()}`;
        const newPayment = {
          apartment: apartments[code].apartment,
          clientName: client.name,
          clientPhone: client.phone,
          amount: payment.amount,
          totalSubpayments: Object.values(payment.subpayments || {}).reduce((total, x) => ({ amount: total.amount + parseFloat(x.amount) }), { amount: 0 }).amount,
          date: cleanDate,
          code,
          row: apartments[code],
        };
        if (programmedDate < today) {
          if (!payment.payedDate) { latePayments.push(newPayment); } else { paidPayments.push(newPayment); }
        } else {
          nextPayments.push(newPayment);
        }
        // TODO insert filter here and store in array
      });
    }
  });
  return [latePayments, nextPayments, paidPayments];
};

const mapStateToProps = state => ({
  apartments: state.data.apartments,
});

export default connect(mapStateToProps, null)(Payments);
