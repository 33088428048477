import { makeStyles } from '@material-ui/styles';
import Colors from './Colors';

export default makeStyles(theme => ({
  body: {
    position: 'absolute !important',
    height: '100% !important',
    width: '100% !important',
    backgroundColor: `${Colors.backgroundColor} !important`,
  },
  whiteText: {
    color: `${Colors.white} !important`,
  },
  darkTextColor: {
    backgroundColor: `${Colors.backgroundColor} !important`,
  },
  initialLoader: {
    position: 'absolute !important',
    top: '50% !important',
    left: '50% !important',
  },
  // Login
  loginLogo: {
    width: '300px !important',
    height: '300px !important',
    marginBottom: '20px !important',
  },
  loginError: {
    color: 'red !important',
    marginTop: '20px !important',
    textAlign: 'center !important',
  },
  loginForm: {
    position: 'absolute !important',
    left: '50% !important',
    marginLeft: '-150px !important',
    marginTop: '10% !important',
    width: '300px !important',
  },
  // TABS CSS START
  verticalTabsContainer: {
    margin: '2vh !important',
    marginTop: '5vh !important',
    display: 'flex !important',
  },
  verticalTabs: {
    maxWidth: '280px !important',
    minWidth: '200px !important',
  },
  verticalTabsTabIndicator: {
    display: 'none !important',
  },
  verticalTabsTab: {
    border: `3px solid ${Colors.backgroundColor} !important`,
  },
  verticalTabsTabSelected: {
    border: `3px solid ${Colors.white} !important`,
  },
  verticalTabsFlex: {
    flexDirection: 'column !important',
  },
  tabLabel: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px !important',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '18px !important',
    },
    color: `${Colors.white} !important`,
  },
  cardContainer: {
    height: '96vh !important',
    width: '96% !important',
    marginTop: '2vh !important',
    marginLeft: '2% !important',
    backgroundColor: `${Colors.backgroundColor} !important`,
    border: `5px solid ${Colors.white} !important`,
  },
  tabInfoContainer: {
    paddingLeft: '25px !important',
    paddingRight: '5px !important',
    marginTop: '-5vh !important',
  },
  loader: {
    marginTop: '20px !important',
  },
  // COMMON CSS START
  contentContainer: {
    height: '92vh !important',
    width: '100vw !important',
    paddingLeft: '30px !important',
  },
  title: {
    fontSize: '50px !important',
    fontWeight: '600 !important',
    marginTop: '-10px !important',
    color: `${Colors.white} !important`,
    textTransform: 'uppercase !important',
    letterSpacing: '5px !important',
    textAlign: 'left !important',
    width: '100vh !important',
  },
  formLabel: {
    fontSize: '30px !important',
    fontWeight: '600 !important',
    marginTop: '10px !important',
    color: `${Colors.white} !important`,
    textTransform: 'uppercase !important',
    letterSpacing: '5px !important',
    textAlign: 'left !important',
    width: '100% !important',
  },
  formInput: {
    backgroundColor: 'white !important',
    borderRadius: '10px !important',
  },
  formInputCompact: {
    backgroundColor: 'white !important',
    borderRadius: '10px !important',
  },
  button: {
    marginTop: '20px !important',
    padding: '5px !important',
    color: `${Colors.white} !important`,
    fontSize: '25px !important',
    width: '300px !important',
  },
  smbutton: {
    padding: '5px !important',
    color: `${Colors.white} !important`,
    fontSize: '15px !important',
  },
  buttonDisabled: {
    marginTop: '20px !important',
    padding: '5px !important',
    color: `${Colors.white} !important`,
    backgroundColor: `${Colors.lightGreyDisabled} !important`,
    fontSize: '25px !important',
    width: '300px !important',
  },
  // MEWS CSS START
  buttonAddNews: {
    padding: '5px !important',
    color: `${Colors.white} !important`,
    fontSize: '25px !important',
    width: '300px !important',
  },
  buttonModalAddNews: {
    position: 'absolute !important',
    bottom: '20px !important',
    right: '20px !important',
    padding: '5px !important',
    color: `${Colors.white} !important`,
    fontSize: '25px !important',
    width: '250px !important',
  },
  buttonModalAddNewsDisabled: {
    position: 'absolute !important',
    bottom: '20px !important',
    right: '20px !important',
    padding: '5px !important',
    backgroundColor: `${Colors.lightGreyDisabled} !important`,
    fontSize: '25px !important',
    width: '250px !important',
  },
  buttonModalCancelAddNews: {
    position: 'absolute !important',
    bottom: '20px !important',
    padding: '5px !important',
    color: `${Colors.lightRed} !important`,
    fontSize: '25px !important',
    width: '250px !important',
  },
  newsContainer: {
    height: '80% !important',
    overflowY: 'auto !important',
    // display: 'flex',
    marginTop: '30px !important',
    marginBottom: '10px !important',
    marginRight: '350px !important',
  },
  newsCard: {
    width: '330px !important',
    height: '400px !important',
    display: 'inline-block !important',
    marginRight: '50px !important',
    marginBottom: '50px !important',
  },
  newsCardContent: {
    height: '200px !important',
    overflowY: 'auto !important',
  },
  newsCardContentNoImage: {
    height: '360px !important',
    overflowY: 'auto !important',
  },
  newsImage: {
    height: '150px !important',
  },
  newsTitle: {
    fontSize: '25px !important',
  },
  newsDate: {
    borderLeft: `1px solid ${Colors.gray} !important`,
    paddingLeft: '5px !important',
    fontSize: '15px !important',
    color: `${Colors.gray} !important`,
    fontWeight: '200 !important',
    display: 'flex !important',
    justifyContent: 'space-between !important',
  },
  newsContent: {
    fontSize: '18px !important',
  },
  // ALERT DIALOG
  alertModalContainer: {
    backgroundColor: `${Colors.backgroundColor} !important`,
    color: `${Colors.white} !important`,
  },
  // NEWS ADD MODAL
  modalContainer: {
    marginTop: '5vh !important',
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
    padding: '20px !important',
    height: '85vh !important',
    width: '80vw !important',
    minWidth: '800px !important',
    backgroundColor: `${Colors.backgroundColor} !important`,
    border: `5px solid ${Colors.white} !important`,
    position: 'relative !important',
  },
  smallModalContainer: {
    marginTop: '10vh !important',
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
    padding: '20px !important',
    width: '100vh !important',
    backgroundColor: `${Colors.backgroundColor} !important`,
    border: `5px solid ${Colors.white} !important`,
    position: 'relative !important',
  },
  subTitle: {
    fontSize: '30px !important',
    fontWeight: '500 !important',
    marginTop: '0px !important',
    color: `${Colors.white} !important`,
    textTransform: 'uppercase !important',
    letterSpacing: '2px !important',
  },
  formControlDialog: {
    width: '48vh !important',
    marginRight: '5px !important',
  },
  formControlDialogWide: {
    width: '70vh !important',
    marginRight: '5px !important',
  },
  formControlDialogSelect: {
    width: '48vh !important',
    marginTop: '16px !important',
    marginBottom: '9px !important',
  },
  inputPlaceholder: {
    color: `${Colors.lightGrey} !important`,
    fontSize: '15px !important',
  },
  inputField: {
    color: `${Colors.white} !important`,
    fontSize: '18px !important',
    fontWeight: '300 !important',
  },
  inputIcon: {
    color: `${Colors.white} !important`,
  },
  inputFieldSelect: {
    color: `${Colors.white} !important`,
    fontSize: '18px !important',
    marginTop: '100px !important',
    fontWeight: '300 !important',
  },
  inputFieldSelect2: {
    color: `${Colors.white} !important`,
    fontSize: '25px !important',
    border: '0px red !important',
    top: '18vh !important',
    position: 'absolute !important',
    fontWeight: '700 !important',
  },
  inputFieldLarge: { width: '45vw !important' },

  // Payments

  inputFieldForm: {
    color: `${Colors.white} !important`,
    fontSize: '18px !important',
    fontWeight: '300 !important',
    marginTop: '-20px !important',
    width: '70px !important',
    textAlign: 'right !important',
  },
  paymentText: {
    color: `${Colors.white} !important`,
    fontSize: '18px !important',
    fontWeight: '600 !important',
  },
  paymentTotalText: {
    color: `${Colors.white} !important`,
    fontSize: '22px !important',
    fontWeight: '700 !important',
  },
  paymentFormRow: {
    display: 'flex !important',
    flexDirection: 'row !important',
    justifyContent: 'space-between !important',
  },
  // end

  iconLeft: {
    marginRight: `${theme.spacing(1)}px !important`,
    verticalAlign: 'text-bottom !important',
  },
  newPhotoContainer: {
    position: 'relative !important',
    textAlign: 'center !important',
  },
  newImgPreviewContainer: {
    maxHeight: '120px !important',
    width: '50vh !important',
    display: 'inline-block !important',
    position: 'relative !important',
  },
  newImgPreview: {
    maxHeight: '120px !important',
    width: '50vh !important',
  },
  newRemoveImgBtn: {
    position: 'absolute !important',
    top: -16,
    right: -16,
    color: 'red !important',
    cursor: 'pointer !important',
  },

  // dropzone
  dropzoneContainer: {
    borderWidth: '2px !important',
    borderStyle: 'dashed !important',
    textAlign: 'center !important',
    borderRadius: '8px !important',
    padding: `${theme.spacing(2)}px !important`,
    borderColor: `${theme.palette.grey[400]} !important`,
    height: '80px !important',
  },
  dropzoneActive: {
    borderColor: `${theme.palette.primary.main} !important`,
  },
  dropzoneContent: {
    display: 'flex !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    height: '100% !important',
  },
  dropzoneIcon: {
    color: `${Colors.white} !important`,
    marginBottom: `${theme.spacing(1)}px !important`,
  },
  dropzoneText: {
    display: 'inline-block !important',
    color: `${Colors.white} !important`,
  },
  dropzoneButton: {
    marginLeft: `${theme.spacing(1)}px !important`,
  },

  // Crop
  cropContainer: {
    backgroundColor: `${Colors.backgroundColor} !important`,
    color: `${Colors.white} !important`,
    border: `5px solid ${Colors.white} !important`,
  },
  cropContentContainer: {
    position: 'relative !important',
    height: '250px !important',
    backgroundColor: `${Colors.backgroundColor} !important`,
    color: `${Colors.white} !important`,
  },
  cropActions: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px !important`,
    background: `${theme.palette.background.default} !important`,
    backgroundColor: `${Colors.backgroundColor} !important`,
  },
  cropOrientationBtns: {
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'flex-end !important',
    marginTop: `${theme.spacing(1)}px !important`,
  },
  cropSliderContainer: {
    display: 'flex !important',
  },
  cropSlider: {
    alignSelf: 'center !important',
    color: `${Colors.white} !important`,
  },
  cropSliderIcon: {
    color: `${Colors.white} !important`,
  },
  cropButton: {
    color: `${Colors.white} !important`,
  },

  // Notifications
  notificationsContainer: {
    height: '55vh !important',
    overflowY: 'auto !important',
    marginTop: '30px !important',
    marginBottom: '10px !important',
  },
  selectCodesContainer: {
    width: '100% !important',
    maxWidth: '100% !important',
  },
  formFieldLarge: { width: '70vh !important' },

  // Users
  // tableFooterContainer: {
  //   position: 'absolute !important',
  //   bottom: '20px !important',
  // },
  tableIconsContainer: {
    flexShrink: '0px !important',
    fontSize: '30px !important',
    marginLeft: `${theme.spacing(2.5)}px !important`,
  },
  tableColumnStatic: {
    position: 'sticky !important',
    left: 0,
    background: 'white',
  },
  // tablePagination: {
  //   fontSize: '20px !important',
  //   color: `${Colors.white} !important`,
  //   borderColor: `${Colors.backgroundColor} !important`,
  // },
  // tableIcon: {
  //   fontSize: '20px !important',
  //   color: `${Colors.white} !important`,
  // },
  tabButtons: {
    '&:hover, &:focus': {
      backgroundColor: `${Colors.white} !important`,
      color: `${Colors.lightBackgroundColor} !important`,
    },
    backgroundColor: `${Colors.lightBackgroundColor} !important`,
    fontSize: '15px !important',
    marginRight: '2px !important',
    paddingBottom: '0px !important',
    color: `${Colors.white} !important`,
    zIndex: '0px !important',
    borderBottomRightRadius: '0px !important',
    borderBottomLeftRadius: '0px !important',
  },
  tabButtonsSelected: {
    '&:hover, &:focus': {
      backgroundColor: `${Colors.white} !important`,
      color: `${Colors.lightBackgroundColor} !important`,
    },
    backgroundColor: `${Colors.white} !important`,
    fontSize: '15px !important',
    marginRight: '2px !important',
    paddingBottom: '0px !important',
    color: `${Colors.backgroundColor} !important`,
    borderBottomRightRadius: '0px !important',
    borderBottomLeftRadius: '0px !important',
  },
  // Select parking lot ant storage unit
  selectModalContainer: {
    overflowY: 'auto !important',
    height: '75% !important',
  },
  // Bills
  billsText: {
    color: 'white !important',
    marginTop: '20px !important',
  },
}));
