import React, { useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import firebase from 'firebase';
import { connect } from 'react-redux';
import logger from '../../actions/helpers';
import { getUserAccount } from './Roles';
import logo from '../../assets/icon.png';
import { login } from '../../actions/UserActions';
import useStyles from '../../theme/Styles';

const SignIn = ({ loginAction }) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleLogin = () => {
    setError('');
    setLoading(true);
    const account = getUserAccount(email);
    if (!account) {
      setError("Account doesn't have admin permissions");
      setLoading(false);
    } else {
      firebase.auth().signInWithEmailAndPassword(email, password)
        .then(() => {
          // logger('user logging in', { email, password });
          loginAction(account);
        })
        .catch((e) => {
          // logger('user unable to log in', { email, password });
          setLoading(false);
          setError(e.message);
        });
    }
  };

  const canSendForm = (!loading && email && password);

  return (
    <div className={classes.contentContainer}>
      <div className={classes.loginForm}>
        <img alt="logo" src={logo} className={classes.loginLogo} />
        <TextField
          style={{ width: '100%' }}
          label={<span className={classes.inputPlaceholder}>Email</span>}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setError('');
          }}
          margin="normal"
          InputProps={{
            className: classes.inputField,
          }}
        />
        <TextField
          style={{ width: '100%' }}
          type="password"
          label={<span className={classes.inputPlaceholder}>Password</span>}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setError('');
          }}
          margin="normal"
          InputProps={{
            className: classes.inputField,
          }}
        />
        <div>
          <Button
            variant="outlined"
            color="primary"
            className={canSendForm ? classes.button : classes.buttonDisabled}
            onClick={() => handleLogin()}
            disabled={!canSendForm}
          >
            Iniciar Sesion
          </Button>
        </div>
        <div className={classes.loginError}>{error}</div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  loginAction: login,
};

export default connect(null, mapDispatchToProps)(SignIn);
