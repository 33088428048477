import React, { useState } from 'react';
import { FormControl, TextField, Button, Select, MenuItem } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import firebase from 'firebase';
import { isEmpty } from 'lodash';
import logger from '../../actions/helpers';
import NewPhoto from '../common/NewPhoto';
import getS3Client from '../../config/S3Client';

import useStyles from '../../theme/Styles';

const AddNews = ({ handleClose }) => {
  const classes = useStyles();
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [stage, setStage] = useState('none');
  const [phase, setPhase] = useState('none');
  const [cover, setCover] = useState('');

  const canSendForm = (
    !isEmpty(title)
      && !isEmpty(body)
  );

  const handleSubmit = () => {
    if (canSendForm) {
      if (cover) {
        const S3Client = getS3Client('postPhotos');
        S3Client.uploadFile(cover)
          .then(pushNewsToFirebase);
      } else {
        pushNewsToFirebase();
      }
    }
  };

  const pushNewsToFirebase = (uploadData = {}) => {
    const photoURL = uploadData.location || '';
    const today = new Date();
    const newNews = {
      title,
      body,
      stage: stage === 'none' ? '' : stage,
      phase: phase === 'none' ? '' : phase,
      cover: photoURL,
      createdAt: `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`,
    };
    firebase.database().ref('/news/').push(newNews, (error) => {
      if (error) {
        logger('AddNews/pushNewsToFirebase/Error', { path: '/news/', error });
      }
      handleClose();
    });
    logger('AddNews/pushNewsToFirebase', { path: '/news/', ...newNews });
  };

  return (
    <div className={classes.modalContainer}>
      <span className={classes.subTitle}>Agregar Noticia</span>
      <br />
      <FormControl component="fieldset" className={classes.formControlDialog}>
        <NewPhoto onPhotoSelected={p => setCover(p)} />
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '45vw' }}>
          <TextField
            id="title"
            label={<span className={classes.inputPlaceholder}>Título</span>}
            value={title}
            onChange={(e) => { setTitle(e.target.value); }}
            margin="normal"
            InputProps={{
              className: classes.inputField,
            }}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Select
              value={stage}
              onChange={e => setStage(e.target.value)}
              className={classes.inputField}
            >
              <MenuItem value="none">Seleccionar Etapa (opcional)</MenuItem>
              <MenuItem value="1">Etapa 1</MenuItem>
              <MenuItem value="2">Etapa 2</MenuItem>
              <MenuItem value="3">Etapa 3</MenuItem>
              <MenuItem value="4">Etapa 4</MenuItem>
            </Select>
            <Select
              value={phase}
              onChange={e => setPhase(e.target.value)}
              className={classes.inputField}
            >
              <MenuItem value="none">Seleccionar Fase (opcional)</MenuItem>
              <MenuItem value="1">1. Pilotes</MenuItem>
              <MenuItem value="2">2. Fundaciones</MenuItem>
              <MenuItem value="3">3. Inicio Estructura</MenuItem>
              <MenuItem value="4">4. Inicio obra fina</MenuItem>
              <MenuItem value="5">5. Fin estructura</MenuItem>
              <MenuItem value="6">6. Fin obra fina</MenuItem>
            </Select>
          </div>
        </div>
        <TextField
          id="body"
          label={<span className={classes.inputPlaceholder}>Contenido</span>}
          value={body}
          multiline
          rows={3}
          onChange={(e) => { setBody(e.target.value); }}
          margin="normal"
          className={classes.inputFieldLarge}
          InputProps={{
            className: classes.inputField,
          }}
        />
      </FormControl>
      <br />
      <Button
        variant="outlined"
        color="primary"
        className={canSendForm ? classes.buttonModalAddNews : classes.buttonModalAddNewsDisabled}
        onClick={handleSubmit}
        disabled={!canSendForm}
      >
        <Add />
        Agregar
      </Button>
      <Button
        color="primary"
        className={classes.buttonModalCancelAddNews}
        onClick={handleClose}
      >
        Cancelar
      </Button>
    </div>
  );
};

export default AddNews;
