import React, { useState } from 'react';
import { connect } from 'react-redux';
import ApartmentsList from './BillsApartmentsList';

import useStyles from '../../theme/Styles';

const PendingBills = ({ apartments }) => {
  const [includeProcessed, setIncludeProcessed] = useState(false);
  const classes = useStyles();

  return (
    <div className={classes.contentContainer}>
      <span className={classes.title}>COMPROBANTES</span>
      <br />
      <input type="checkbox" checked={includeProcessed} onChange={() => setIncludeProcessed(!includeProcessed)} />
      <span className={classes.whiteText}>Incluir departamentos con todos sus comprobantes procesados</span>
      <br />
      <br />
      <ApartmentsList
        apartments={apartments}
        includeProcessed={includeProcessed}
      />
    </div>
  );
};


const mapStateToProps = state => ({
  apartments: state.data.apartments,
});


export default connect(mapStateToProps, null)(PendingBills);
