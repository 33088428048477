import React from 'react';
import { connect } from 'react-redux';
import StepsList from './StepsList';
import useStyles from '../../theme/Styles';

const Steps = ({ steps }) => {
  const classes = useStyles();

  return (
    <div className={classes.contentContainer}>
      <span className={classes.title}>Etapas</span>
      <StepsList steps={steps} />
    </div>
  );
};

const mapStateToProps = state => ({
  steps: state.data.steps,
});


export default connect(mapStateToProps, null)(Steps);
